import React, { useEffect, useState } from 'react';
import { Chip, Container } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { getStockHistory, getStocks } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { safeJSONParse } from '../../../../utils/common';

export default function SingleStockItem() {
  const { id } = useParams();
  const { themeStretch } = useSettings();
  const [itemData, setItemData] = useState({});

  let stocks;
  let stockHistory;
  ({ data: stocks, stockHistory } = useSelector((state) => state?.stock));
  const { selectedLocation } = useSelector((state) => state?.location);

  useEffect(() => {
    dispatch(getStocks(selectedLocation?.id, -1, id, -1));
    dispatch(getStockHistory(id));
  }, [selectedLocation]);

  useEffect(() => {
    console.log(id);
  }, [id]);

  useEffect(() => {
    console.log(stockHistory);
  }, [stockHistory]);

  useEffect(() => {
    console.log(itemData);
  }, [itemData]);

  useEffect(() => {
    const tempStock = stocks?.[0];
    setItemData({
      ...tempStock,
      foodInfo: safeJSONParse(tempStock?.foodInfo),
      foodSupplierInfo: safeJSONParse(tempStock?.foodSupplierInfo),
      locationInfo: safeJSONParse(tempStock?.locationInfo),
      supplierInfo: safeJSONParse(tempStock?.supplierInfo),
      unitInfo: safeJSONParse(tempStock?.unitInfo),
    });
  }, [stocks]);

  return (
    <Page title='View Stock'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='View Stock'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Stock',
              href: '',
            },
            { name: 'View All' },
          ]}
          action={[]}
        />
        <Timeline position='alternate'>
          {stockHistory?.map((value, index) => (
            <TimelineItem key={`${value?.code}_${value?.orderId}_${index}`}>
              <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                {moment(value?.stockDateTime).format('DD MMMM YYYY')}
                <br />
                {moment(value?.stockDateTime).format('hh:mm A')}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <AddIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant='h6' component='span'>
                  {value?.titleCode} (ID: {value?.stockId})
                </Typography>
                <Typography>{value?.description}</Typography>
                <Chip sx={{ margin: '5px' }} label={`${value?.stockCurrentQty} ${itemData?.unitInfo?.unitName}`} size='medium' variant='outlined' icon={<InventoryIcon />} color='primary' />
                <Chip sx={{ margin: '5px' }} label={value?.userName} size='medium' variant='outlined' icon={<AccountCircleIcon />} color='primary' />
                <Chip sx={{ margin: '5px' }} label={value?.locationName} size='medium' variant='outlined' icon={<LocationOnIcon />} color='primary' />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Container>
    </Page>
  );
}
