import React, { useEffect, useState } from 'react';
import { Box, Card, Chip, Container, SwipeableDrawer, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../../utils/common';
import { LoadingButton } from '@mui/lab';
import { getStockOrders, updateOrderStatus } from '../../../../redux/slices/stockManagementRedux/stockOrderRedux';
import { getUsers } from '../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';

const ViewStockOrders = () => {
  const { themeStretch } = useSettings();
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  let userData;
  ({ data: userData } = useSelector((state) => state.user));

  let allLocations;
  let selectedLocation;
  ({ data: allLocations, selectedLocation } = useSelector((state) => state.location));

  let requestedItemList;
  let stockOrderUpdated;
  ({ data: requestedItemList, addData: stockOrderUpdated } = useSelector((state) => state.stockOrder));

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getStockOrders(selectedLocation?.id));
  }, [selectedLocation]);

  useEffect(() => {
    setData(requestedItemList);
  }, [requestedItemList]);

  useEffect(() => {
    if (stockOrderUpdated?.data) {
      dispatch(getStockOrders(selectedLocation?.id));
      setIsModelOpen(false);
    }
  }, [selectedLocation?.id, stockOrderUpdated]);

  const columns = data?.[0]
    ? Object.keys(data?.[0])
        .map((value) => {
          if (['transferAddedUserId', 'stockorders_food_details', 'stockDetailsArray'].includes(value)) {
            return null;
          } else if (value === 'transferStatus') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                switch (row?.original?.transferStatus) {
                  case '0':
                    return <Chip label={'Pending'} color={'warning'} />;
                  case '1':
                    return <Chip label={'Done'} color={'success'} />;
                  default:
                    return '-';
                }
              },
            };
          } else if (value === 'dateTime') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return utcMoment(row?.original?.dateTime).format('YYYY-MM-DD HH:mm:ss');
              },
            };
          } else if (value === 'requiredDate') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return utcMoment(row?.original?.requiredDate).format('YYYY-MM-DD');
              },
            };
          } else if (value === 'userId') {
            return {
              accessorKey: value,
              header: 'Created By',
              Cell: ({ cell, row }) => {
                const selectedUserData = userData?.find((userIndex) => userIndex.id === row?.original?.[value]);
                return selectedUserData?.name;
              },
            };
          } else if (value === 'status') {
            return {
              accessorKey: value,
              header: 'Status',
              Cell: ({ cell, row }) => (row?.original?.[value] === 1 ? <Chip label={'Completed'} color={'success'} /> : <Chip label={'Pending'} color={'warning'} />),
            };
          } else if (['fromLocation', 'toLocation'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                const locationData = allLocations?.find((location) => location.id === row?.original?.[value]);
                return locationData?.name;
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        .filter(Boolean)
    : [];

  return (
    <Page title='View Stock Orders'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading='View Stock Orders' links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: '' }, { name: 'View Stock Orders' }]} action={[]} />

        {accessVerify('STOCK_ORDER_LIST_VIEW') && (
          <DataGridTable
            name={'View Stock Orders'}
            data={data}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            isRowClickable={true}
            keyField={'id'}
            enableRowActions={true}
            onRowClick={(row) => {
              setIsModelOpen(true);
              setSelectedData({
                ...row.original,
                stockorders_food_details: safeJSONParse(row?.original?.stockorders_food_details),
              });
            }}
            renderRowActionItems={(row) => []}
          />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>Stock Order {selectedData?.id}</h1>
            <br />
            <br />
            {selectedData?.status === 0 && (
              <LoadingButton
                type='submit'
                variant='contained'
                size='large'
                style={{ width: '100%' }}
                onClick={() => {
                  dispatch(updateOrderStatus({ id: selectedData?.id, status: 1 }));
                }}
              >
                Mark As Completed
              </LoadingButton>
            )}
            <br />
            <br />
            <Card
              sx={{
                p: 3,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#eee',
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align='center'>Minimum Qty</TableCell>
                    <TableCell align='center'>Required Qty</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {selectedData?.stockorders_food_details?.map((dataIndex) => {
                    return (
                      <TableRow key={dataIndex?.id}>
                        <TableCell>
                          {dataIndex?.foodDetails?.name} - {dataIndex?.foodDetails?.description} ({dataIndex?.foodDetails?.code})
                        </TableCell>
                        <TableCell align='center'>{dataIndex?.minQty}</TableCell>
                        <TableCell align='center'>{dataIndex?.requiredQty}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          </Box>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
};

export default ViewStockOrders;
