import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { capitalize, safeJSONParse } from '../../../utils/common';
import { addCashFlowCategories, getCashFlowCategories, updateCashFlowCategories } from '../../../redux/slices/financeRedux/cashFlowCategoriesRedux';

export default function CashFlowCategories() {
  const dataModel = {
    id: '',
    name: '',
    description: '',
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [assetsList, setAssetsList] = useState([]);

  const { data, addData } = useSelector((state) => state.cashFlowCategories);

  useEffect(() => {
    dispatch(getCashFlowCategories());
  }, []);

  useEffect(() => {
    setAssetsList(
      data?.map((value) => {
        return {
          ...value,
          locationData: safeJSONParse(value?.locationData),
          userData: safeJSONParse(value?.userData),
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getCashFlowCategories());
    }
  }, [addData]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !assetsList?.[0]
    ? []
    : Object.keys(assetsList?.[0])
        .map((value) => {
          if (['isVerifier', 'isIncrement', 'locationData', 'userData'].includes(value)) {
            return {};
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    if (selectedDataObj.name.length === 0) {
      toast.error('Asset name is required!');
    } else if (selectedDataObj.description.length === 0) {
      toast.error('Asset description is required!');
    } else {
      dispatch(isAdd ? addCashFlowCategories(selectedDataObj) : updateCashFlowCategories(selectedDataObj));
    }
  };
  return (
    <Page title={`CashFlow Categories`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`CashFlow Categories`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Finance',
              href: '',
            },
            { name: `CashFlow Categories` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New CashFlow Category
            </Button>
          }
        />

        <DataGridTable
          name={'Payment Types'}
          data={assetsList}
          column={columns}
          isLoading={false}
          onRowClick={(row) => {
            manageModel(row?.original, 'edit');
          }}
          isRowClickable={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowSelection={false}
          enableRowActions={false}
          renderRowActionItems={(value, closeMenu) => []}
        />

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} CashFlow Categories</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Category Information
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Name' value={selectedDataObj?.name} onChange={(e) => updateEditingData('name', e.target.value)} />
                    <TextField fullWidth label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                    {isAdd ? 'Add CashFlow Categories' : 'Save Changes'}
                  </LoadingButton>
                  <Button
                    color='info'
                    variant='outlined'
                    size='large'
                    style={{ width: '100%' }}
                    onClick={() => {
                      manageModel(dataModel, 'add');
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
