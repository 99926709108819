import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, IconButton, Stack, SwipeableDrawer, TextField, Tooltip, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../../../redux/store';
import useSettings from '../../../../../hooks/useSettings';
import Page from '../../../../../components/Page';
import Iconify from '../../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import DataGridTable from '../../../../../components/table/DataGridTable';
import { FormProvider, RHFUploadAvatar } from '../../../../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import { fData } from '../../../../../utils/formatNumber';
import { imageUpload } from '../../../../../inteceptor';
import { toast } from 'react-toastify';
import { getSpecificUserData } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { addDocument, updateDocument } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userDocumentUploadRedux';
import moment from 'moment';
import Label from '../../../../../components/Label';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { capitalize } from '../../../../../utils/common';

export default function UserUploadedFiles({ userId }) {
  const dataModel = {
    url: null,
    documentTypeId: null,
  };
  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);

  const { selectedUser, documentTypes, documents } = useSelector((state) => state.user);
  const { addData } = useSelector((state) => state.document);

  useEffect(() => {
    if (userId) dispatch(getSpecificUserData(userId));
  }, [userId]);

  useEffect(() => {
    setDocumentsList(
      documents?.map((value, index) => {
        return {
          Id: value?.id,
          dateTime: moment(value?.dateTime).format('Do MMMM YYYY, h:mm:ss a'),
          documentType: documentTypes?.filter((documentType) => documentType?.id === value?.documentTypeId)?.[0]?.name,
          url: value?.url,
          status: value?.status,
        };
      })
    );
    setIsModelOpen(false);
  }, [documents]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedObject(dataModel);
      } else {
        setSelectedObject(modelData);
      }
    }
  };

  const NewProductSchema = Yup.object().shape({
    url: Yup.string().required('Please upload document and try again'),
  });

  const defaultValues = useMemo(
    () => ({
      url: selectedObject?.url || '',
      documentTypeId: selectedObject?.documentTypeId || '',
    }),
    [selectedObject]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isAdd && selectedObject) {
      reset(defaultValues);
    }
    if (!isAdd) {
      reset(defaultValues);
    }
  }, [isAdd, selectedObject]);

  const onSubmit = async (value) => {
    try {
      const tempDataObj = {
        url: value?.url || '',
        documentTypeId: value?.documentTypeId?.id || '',
        userId: selectedUser?.[0]?.id || '',
      };
      if (isAdd) {
        dispatch(addDocument(tempDataObj));
      } else {
        dispatch(updateDocument({ ...tempDataObj, id: selectedObject?.id }));
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getSpecificUserData(userId));
    }
  }, [addData]);

  const columns = !documentsList?.[0]
    ? []
    : Object.keys(documentsList?.[0]).map((value) => {
        return {
          accessorKey: value,
          header: capitalize(value),
        };
      });

  const [rowSelection, setRowSelection] = useState(false);

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      imageUpload(
        file,
        'UserPersonalDocuments',
        (data) => {
          if (data?.status) {
            setValue('url', data?.url);
          } else {
            console.log(data);
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
        }
      );

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <Page title='Location List'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading=''
          links={[]}
          action={
            <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => manageModel(dataModel, 'add')}>
              Add Document
            </Button>
          }
        />

        <DataGridTable
          name={'User uploaded Files'}
          data={documentsList}
          column={columns.map((value) => {
            if (value?.accessorKey === 'url') {
              return {
                ...value,
                Cell: ({ cell }) => (
                  <a href={cell.getValue()} target='_blank' rel='noreferrer'>
                    <img src={cell.getValue()} alt={cell.getValue()} />
                  </a>
                ),
              };
            } else if (value?.accessorKey === 'status') {
              return {
                ...value,
                Cell: ({ cell }) => (
                  <Label color={cell.getValue() === 'approved' ? 'success' : cell.getValue() === 'declined' ? 'error' : 'warning'} sx={{ textTransform: 'capitalize' }}>
                    {cell.getValue()}
                  </Label>
                ),
              };
            } else {
              return value;
            }
          })}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowActions={true}
          renderRowActionItems={(value, closeMenu) => {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                {['approved', 'declined'].includes(value?.original?.status) && (
                  <Tooltip title={'Set to Pending'}>
                    <IconButton
                      color='warning'
                      onClick={() => {
                        dispatch(
                          updateDocument({
                            ...value?.original,
                            status: 'pending',
                            documentTypeId: value?.original?.documentTypeId?.id,
                          })
                        );
                      }}
                    >
                      <PendingIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {['pending', 'declined'].includes(value?.original?.status) && (
                  <Tooltip title={'Set to Approved'}>
                    <IconButton
                      color='success'
                      onClick={() => {
                        dispatch(
                          updateDocument({
                            ...value?.original,
                            status: 'approved',
                            documentTypeId: value?.original?.documentTypeId?.id,
                          })
                        );
                      }}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {['pending', 'approved'].includes(value?.original?.status) && (
                  <Tooltip title={'Set to Declined'}>
                    <IconButton
                      color='error'
                      onClick={() => {
                        dispatch(
                          updateDocument({
                            ...value?.original,
                            status: 'declined',
                            documentTypeId: value?.original?.documentTypeId?.id,
                          })
                        );
                      }}
                    >
                      <ThumbDownAltIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            );
          }}
        />

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Document</h1>
          </Box>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <RHFUploadAvatar
                      name='url'
                      accept='image/*'
                      maxSize={5045728}
                      onDrop={handleDrop}
                      helperText={
                        <Typography
                          variant='caption'
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(1048576)}
                        </Typography>
                      }
                    />
                  </Card>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Controller
                        name='documentTypeId'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            onChange={(event, newValue) => {
                              field.onChange(newValue);
                            }}
                            options={documentTypes?.map((value) => {
                              return {
                                ...value,
                                label: value?.name,
                                value: value?.id,
                              };
                            })}
                            renderInput={(params) => <TextField label='Document Type' {...params} />}
                          />
                        )}
                      />
                    </Stack>
                  </Card>
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' loading={isSubmitting} style={{ width: '100%' }}>
                      {isAdd ? 'Add Document' : 'Save Changes'}
                    </LoadingButton>
                    <Button color='info' variant='outlined' size='large' loading={isSubmitting} style={{ width: '100%' }} onClick={() => manageModel(dataModel, 'add')}>
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
