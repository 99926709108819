import { BASE_URL, ENV } from './config';
import { dispatch } from './redux/store';
import { doneLoading, startLoading } from './redux/slices/templateRedux/productManagementRedux/loadingRedux';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';

export const get = async (path, queryParams = {}) => {
  dispatch(startLoading());

  // Construct query string from queryParams object
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${BASE_URL}${path}${queryString ? '?' + queryString : ''}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
    },
    method: 'GET',
  });

  dispatch(doneLoading());

  const responseValue = await response.json();
  if (!responseValue.status) {
    toast.error(responseValue?.message, {
      render: responseValue?.message,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  }
  return responseValue;
};

export const post = async (path, body) => {
  dispatch(startLoading());
  let toastId;
  if (path !== 'auth') {
    toastId = toast.loading('Loading...');
  }
  const response = await fetch(`${BASE_URL}${path}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
    },
    body: JSON.stringify(body),
    method: 'POST',
  });

  dispatch(doneLoading());

  const responseValue = await response.json();
  if (!responseValue.status) {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  } else {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  }
  return responseValue;
};

export const put = async (path, body) => {
  dispatch(startLoading());

  const toastId = toast.loading('Loading...');
  const response = await fetch(`${BASE_URL}${path}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
    },
    body: JSON.stringify(body),
    method: 'PUT',
  });

  dispatch(doneLoading());

  const responseValue = await response.json();
  if (!responseValue.status) {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  } else {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  }
  return responseValue;
};

export const deleteMethod = async (path, body) => {
  dispatch(startLoading());

  const toastId = toast.loading('Loading...');
  const response = await fetch(`${BASE_URL}${path}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
    },
    body: JSON.stringify(body),
    method: 'DELETE',
  });

  dispatch(doneLoading());

  const responseValue = await response.json();
  if (!responseValue.status) {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  } else {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  }
  return responseValue;
};

export const imageUpload = (file, folderName, successCallback, errorCallback) => {
  dispatch(startLoading());

  const toastId = toast.loading('Loading...');

  const originalFileName = file.name;

  new Compressor(file, {
    quality: 0.5,
    async success(result) {
      let compressedFile = result;
      let compressedSize = compressedFile.size;
      while (compressedSize > 1048576) {
        const quality = compressedSize / 1048576;
        compressedFile = await new Promise((resolve) => {
          new Compressor(result, {
            quality: quality,
            success(result) {
              resolve(result);
            },
          });
        });
        compressedSize = compressedFile.size;
      }

      const formData = new FormData();
      formData.append('file', compressedFile, originalFileName);
      formData.append('folderName', folderName);
      formData.append('tag', `WEB_${ENV}`);

      try {
        const response = await fetch(`${BASE_URL}common/imageUpload`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
          },
        });

        const data = await response.json();
        if (data?.status) {
          toast.update(toastId, {
            render: 'Image Uploaded Successfully',
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: true,
            isLoading: false,
          });
        } else {
          toast.update(toastId, {
            render: 'Image Upload Failed',
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: true,
            isLoading: false,
          });
        }
        dispatch(doneLoading());
        successCallback(data);
      } catch (error) {
        toast.update(toastId, {
          render: 'Image Upload Failed',
          type: toast.TYPE.ERROR,
          autoClose: 2000,
          closeButton: true,
          isLoading: false,
        });
        console.error(error);
        dispatch(doneLoading());
        errorCallback('Upload Failed.');
      }
    },
    error(err) {
      console.error(err.message);
      toast.update(toastId, {
        render: 'Image Upload Failed',
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: true,
        isLoading: false,
      });
      dispatch(doneLoading());
      errorCallback('Compression Failed.');
    },
  });
};
