import React, { useEffect, useState } from 'react';
import { Box, Card, Chip, Container, SwipeableDrawer, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { accessVerify, capitalize, safeJSONParse } from '../../../../utils/common';
import { getStockTransfers, updateTransferReceived } from '../../../../redux/slices/stockManagementRedux/stockTransferRedux';
import moment from 'moment/moment';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

const ViewTransfer = () => {
  const { themeStretch } = useSettings();
  const [purchaseNotesList, setPurchaseNotesList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const { data: stockTransferData, addData: stockTransferUpdatedData } = useSelector((state) => state?.stockTransfer);
  const { selectedLocation } = useSelector((state) => state?.location);

  useEffect(() => {
    dispatch(getStockTransfers());
  }, [selectedLocation]);

  useEffect(() => {
    setPurchaseNotesList(
      stockTransferData?.map((value) => {
        return {
          ...value,
          stockDetailsArray: safeJSONParse(value?.stockDetailsArray),
        };
      })
    );
  }, [stockTransferData]);

  const columns = purchaseNotesList?.[0]
    ? Object.keys(purchaseNotesList?.[0])
        .map((value) => {
          if (value === 'transferAddedUserId' || value === 'stockDetailsArray') {
            return null;
          } else if (value === 'transferStatus') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                switch (row?.original?.transferStatus) {
                  case '0':
                    return <Chip label={'Pending'} color={'warning'} />;
                  case '1':
                    return <Chip label={'Done'} color={'success'} />;
                  default:
                    return '-';
                }
              },
            };
          } else if (value === 'transferDateTime') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => moment(row.original.transferDateTime).format('DD MMMM YYYY'),
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        .filter(Boolean)
    : [];

  const columnForStockDetails = selectedData?.stockDetailsArray?.[0]
    ? Object.keys(selectedData?.stockDetailsArray?.[0])
        .map((value) => {
          if (value === 'stockDetails') {
            return {
              accessorKey: value,
              header: 'Stock Code',
              Cell: ({ cell, row }) => row?.original?.stockDetails?.stockCode,
            };
          }
          if (value === 'transferStockStatus') {
            return {
              accessorKey: value,
              header: 'Transfer Status',
              Cell: ({ cell, row }) => (row?.original?.transferStockStatus === 0 ? <Chip label={'Pending'} color={'warning'} /> : <Chip label={'Done'} color={'success'} />),
            };
          }
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        .filter(Boolean)
    : [];

  useEffect(() => {
    if (stockTransferUpdatedData?.data) {
      setIsModelOpen(false);
    }
  }, [stockTransferUpdatedData]);

  return (
    <Page title='View Transferred Stock'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading='View Transferred Stock' links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: '' }, { name: 'View Transferred Stock' }]} action={[]} />

        {accessVerify('STOCK_TRANSFER_VIEW') && (
          <DataGridTable
            name={'View Stock'}
            data={purchaseNotesList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            keyField={'stockId'}
            enableRowActions={false}
            isRowClickable={true}
            onRowClick={(row) => {
              setIsModelOpen(true);
              setSelectedData({ ...row.original, location: selectedLocation });
            }}
            renderRowActionItems={(row) => []}
          />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>Transfer Receiving Management</h1>
            <br />
            <br />
            <Card
              sx={{
                p: 3,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#eee',
                },
              }}
            >
              <TextField
                type='text'
                label={`Enter Barcode`}
                sx={{ width: '100%' }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.target.select();
                    setSelectedData((selectedDataValue) => {
                      return {
                        ...selectedDataValue,
                        stockDetailsArray: selectedDataValue?.stockDetailsArray?.map((currentItemList) => {
                          if (currentItemList?.stockDetails?.stockCode === event.target.value) {
                            return {
                              ...currentItemList,
                              transferStockStatus: 1,
                            };
                          }
                          return currentItemList;
                        }),
                      };
                    });
                  }
                }}
              />
              <br />
              <br />
              <DataGridTable
                name={'View Stock'}
                data={selectedData?.stockDetailsArray}
                column={columnForStockDetails}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                keyField={'stockId'}
                isLoading={false}
                enablePinning={false}
                isRowClickable={false}
                enableRowActions={false}
                enableRowSelection={false}
                renderRowActionItems={(row) => []}
                maxHeight={`34vh`}
              />
            </Card>
            <br />
            {selectedData?.transferStatus === '0' && (
              <Card
                sx={{
                  p: 3,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#eee',
                  },
                }}
              >
                <LoadingButton
                  onClick={() => {
                    if (selectedData?.stockDetailsArray?.filter((value) => value?.transferStockStatus === 0).length > 0) {
                      toast.warning('All the items needs to be received to confirm this transfer!');
                    } else {
                      dispatch(getStockTransfers());
                      dispatch(updateTransferReceived(selectedData));
                    }
                  }}
                  variant='contained'
                  size='large'
                  style={{ width: '100%' }}
                >
                  Save Changes
                </LoadingButton>
              </Card>
            )}
          </Box>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
};

export default ViewTransfer;
