import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from '../../../../../../redux/store';
import { useEffect, useState } from 'react';
import { Grid, SwipeableDrawer, TextField } from '@mui/material';
import PaymentTypeBlock from './paymentTypeBlock';
import { LoadingButton } from '@mui/lab';
import { fCurrency } from '../../../../../../utils/formatNumber';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other} sx={{ textAlign: 'left', width: '100%' }}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function PaymentCollector({ data, setData, onClickSound, onChangeTab }) {
  const { data: paymentTypeList } = useSelector((state) => state?.paymentTypes);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const currencyList = [5000, 1000, 500, 100, 50, 20, 10, 5, 2, 1];

  const totalPayable = data?.orderInfo?.cart?.reduce((sum, cartItem) => {
    const pricingData = cartItem?.pricingData?.find((pricingDataIndex) => pricingDataIndex?.saleTypeId === data?.deliveryInfo?.id);
    return sum + pricingData?.pricing * cartItem?.quantity;
  }, 0);

  const getTotalPaidAmount = (data?.paymentRecords?.payments || []).reduce((sum, value) => sum + Number(value?.amount || 0), 0);

  useEffect(() => {
    const processedPaymentList = paymentTypeList?.filter((value) => {
      return value?.saleTypeList?.split(',').includes(data?.deliveryInfo?.id.toString());
    });
    setData((currentDataState) => ({
      ...currentDataState,
      paymentRecords: {
        totalPayable: totalPayable,
        totalPaidAmount: getTotalPaidAmount,
        balance: Math.abs(totalPayable - getTotalPaidAmount),
        payments: processedPaymentList?.map((paymentData) => {
          return {
            ...paymentData,
            isIncrement: paymentData?.isIncrement === 1,
            amount: currentDataState?.paymentRecords?.payments?.find((value) => value?.id === paymentData?.id)?.amount || 0,
          };
        }),
      },
    }));
  }, [paymentTypeList, totalPayable, selectedPaymentType, getTotalPaidAmount]);

  const handlePriceChange = (directSet = false, amount) => {
    setData((currentDataState) => ({
      ...currentDataState,
      paymentRecords: {
        ...currentDataState?.paymentRecords,
        payments: currentDataState?.paymentRecords?.payments?.map((paymentRecordIndex) => {
          if (paymentRecordIndex?.id === selectedPaymentType?.id) {
            if (paymentRecordIndex?.isIncrement) {
              return {
                ...paymentRecordIndex,
                amount: directSet ? Number(amount) : Number(paymentRecordIndex?.amount) + Number(amount),
              };
            } else {
              return {
                ...paymentRecordIndex,
                amount: directSet ? Number(amount) : Number(paymentRecordIndex?.amount) - Number(amount),
              };
            }
          }
          return {
            ...paymentRecordIndex,
            amount: paymentRecordIndex?.amount,
          };
        }),
      },
    }));
  };

  const getPaymentTypeTotalAmount = () => {
    return data?.paymentRecords?.payments?.find((value) => value?.id === selectedPaymentType?.id)?.amount || '-';
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Grid container justifyContent='center'>
          {data?.paymentRecords?.payments?.map((paymentData, menuIndex) => (
            <Grid item key={menuIndex} md={4}>
              <PaymentTypeBlock
                title={paymentData?.name}
                description={paymentData?.description}
                isIncrement={paymentData?.isIncrement}
                price={paymentData?.amount}
                onClickSound={onClickSound}
                addToCart={() => {
                  setSelectedPaymentType(paymentData);
                  setIsModelOpen(true);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <SwipeableDrawer
          anchor={'right'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '60%',
              height: '90%',
              marginTop: '3%',
              borderRadius: '10px 0 0 10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h2>Enter your collection in {selectedPaymentType?.name},</h2>
          </Box>
          <Grid container justifyContent='left' p={3}>
            <Grid item md={4}>
              <h6>Payable Amount</h6>
              <h1>{fCurrency(data?.paymentRecords?.totalPayable)}</h1>
            </Grid>
            <Grid item md={4}>
              <h6>Total Entered Amount</h6>
              <h1>{fCurrency(getTotalPaidAmount)}</h1>
            </Grid>
            <Grid item md={4}>
              <h6>Amount Left to Pay</h6>
              <h1>{fCurrency(data?.paymentRecords?.totalPayable - getTotalPaidAmount)}</h1>
            </Grid>
          </Grid>

          <Grid container justifyContent='left' p={3}>
            <Grid item md={3}>
              <TextField
                type='number'
                variant='outlined'
                label={`Amount`}
                sx={{ width: '90%', marginTop: '20px', height: '16px' }}
                placeholder={`Amount in LKR`}
                value={getPaymentTypeTotalAmount()}
                onChange={(event) => {
                  onClickSound();
                  handlePriceChange(true, event?.target?.value);
                }}
              />
            </Grid>
            {currencyList?.map((value) => (
              <Grid item md={3}>
                <LoadingButton
                  onClick={() => {
                    onClickSound();
                    handlePriceChange(false, value);
                  }}
                  variant='outlined'
                  size='large'
                  style={{ width: '90%', marginTop: '20px' }}
                >
                  Add {fCurrency(value)}
                </LoadingButton>
              </Grid>
            ))}
            <br />
            <br />
            <br />
            {/*<LoadingButton*/}
            {/*  onClick={() => {*/}
            {/*    onClickSound();*/}
            {/*    handlePriceChange(false, data?.paymentRecords?.totalPayable - getTotalPaidAmount);*/}
            {/*    setIsModelOpen(false);*/}
            {/*  }}*/}
            {/*  variant='contained'*/}
            {/*  size='large'*/}
            {/*  color={'error'}*/}
            {/*  style={{ width: '45%', marginTop: '20px', marginLeft: '2.5%' }}*/}
            {/*>*/}
            {/*  Add {fCurrency(data?.paymentRecords?.totalPayable - getTotalPaidAmount)} & Close*/}
            {/*</LoadingButton>*/}
            <LoadingButton
              onClick={() => {
                onClickSound();
                setIsModelOpen(false);
              }}
              variant='contained'
              size='large'
              color={'info'}
              style={{ width: '100%', marginTop: '50px', marginLeft: '2.5%', minHeight: '70px', fontWeight: 800, fontSize: '1.6em' }}
            >
              Confirm & Close
            </LoadingButton>
          </Grid>
        </SwipeableDrawer>
      </Grid>
    </>
  );
}
