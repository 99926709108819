// routes
import { PATH_DASHBOARD } from './routes/paths';

export const PRINTER_TOOL_DOWNLOAD_URL = 'https://storage.googleapis.com/bubblemania/PROD_ASSETS/PrinterTool.zip';
// export const BASE_URL = 'https://bubblemelk.expergen.com/';
// export const BASE_URL = 'http://localhost:8080/';
export const BASE_URL = process.env?.REACT_APP_BASEURL ? process.env?.REACT_APP_BASEURL : 'https://api-bubblemania-beta.expergen.com/';
export const ENV = 'BETA';
export const BUSINESS_NAME = 'ExperSend';

export const COMPANY_DATA_FOR_PDF = {
  name: 'Expersend',
  address: '114/1/1 Piliyandala Rd, Pannipitiya 10230',
  phone: '0778804992',
};

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || 'https://minimal-assets-api.vercel.app';

export const FIREBASE_API = {
  apiKey: "AIzaSyDzxheOGNLPcLIXAzzDwVf7iDiGgWbda2w",
  authDomain: "expersend-lk.firebaseapp.com",
  projectId: "expersend-lk",
  storageBucket: "expersend-lk.appspot.com",
  messagingSenderId: "1088575035814",
  appId: "1:1088575035814:web:f0d479ccbe824720bd9767",
  measurementId: "G-CMHNSKPHK3"
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.sales.list; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'orange',
  themeLayout: 'vertical',
  themeStretch: true,
};
