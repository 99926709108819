import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  itemsList: [],
  addData: {},
  allStockOrderStatus: [],
  sorted: [],
};

const slice = createSlice({
  name: 'StockOrder',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },
    getStockOrdersSuccess(state, action) {
      state.data = action.payload.data;
    },
    getItemsListSuccess(state, action) {
      state.itemsList = action.payload.data;
    },
    getAllStockOrderStatusesSuccess(state, action) {
      state.getAllStockOrderStatus = action.payload.data;
    },
    getStockOrdersHistorySuccess(state, action) {
      state.StockOrderHistory = action.payload.data;
    },
    addOrUpdateStockOrders(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    clearAll(state) {
      state.addData = {};
      state.error = {};
    },
    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },
    onNextStep(state) {
      state.checkout.activeStep += 1;
    },
    onGotoStep(state, action) {
      state.checkout.activeStep = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getStockOrderItems(location) {
  return async () => {
    try {
      const response = await get(`StockOrderItems?locationId=${location}`);
      if (response.status) {
        dispatch(slice.actions.getItemsListSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getStockOrders(location) {
  return async () => {
    try {
      const response = await get(`stockOrder?locationId=${location}`);
      if (response.status) {
        dispatch(slice.actions.getStockOrdersSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addStockOrder(body) {
  return async () => {
    try {
      const response = await post(`StockOrder`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateStockOrders(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateOrderStatus(body) {
  return async () => {
    try {
      const response = await put(`StockOrderStatus`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateStockOrders(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
