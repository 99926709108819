import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  averageFoodPricesForStock: [],
  foodMenuData: [],
  foodVariationData: [],
  addData: {},
  sorted: [],
};

const slice = createSlice({
  name: 'Food',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    // GET FoodManagement
    getFoodsSuccess(state, action) {
      state.data = action.payload.data;
    },
    // GET Food Menu Data
    getFoodsMenuSuccess(state, action) {
      state.foodMenuData = action.payload.data;
    },
    // GET Food Variation Data
    getFoodsVariationSuccess(state, action) {
      state.foodVariationData = action.payload.data;
    },
    // GET FoodManagement
    getAllFoodsSuccessAndWindowClose(state, action) {
      window.close();
      state.allData = action.payload.data;
    },
    // GET FoodManagement
    getAllFoodsSuccess(state, action) {
      state.allData = action.payload.data;
    },
    // GET FoodManagement
    getFoodAveragePricingForStockSuccess(state, action) {
      state.averageFoodPricesForStock = action.payload.data;
    },

    addOrUpdateFoods(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getAllFoodsAndClose() {
  return async () => {
    try {
      const response = await get(`allFood`);
      if (response.status) {
        dispatch(slice.actions.getAllFoodsSuccessAndWindowClose(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllFoods() {
  return async () => {
    try {
      const response = await get(`allFood`);
      if (response.status) {
        dispatch(slice.actions.getAllFoodsSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFoodAveragePricingForStock() {
  return async () => {
    try {
      const response = await get(`getFoodAveragePricingForStock`);
      if (response.status) {
        dispatch(slice.actions.getFoodAveragePricingForStockSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMenuAndVariationFoods() {
  return async () => {
    try {
      const responseMenu = await get(`Food?type=menu`);
      if (responseMenu.status) {
        dispatch(slice.actions.getFoodsMenuSuccess(responseMenu));
        const responseVariation = await get(`Food?type=variation`);
        if (responseVariation.status) {
          dispatch(slice.actions.getFoodsVariationSuccess(responseVariation));
        } else {
          dispatch(slice.actions.hasError(responseVariation.message));
        }
      } else {
        dispatch(slice.actions.hasError(responseMenu.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFoods(type) {
  return async () => {
    try {
      const response = await get(`Food?type=${type}`);
      if (response.status) {
        dispatch(slice.actions.getFoodsSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addFood(body) {
  return async () => {
    try {
      const response = await post(`Food`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateFoods(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateFood(body) {
  return async () => {
    try {
      const response = await put(`Food`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateFoods(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
