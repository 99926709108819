// https://www.veryicon.com/icons/miscellaneous/indata/walking-1.html

import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import DataFilter from './components/filter';
import { useDispatch } from '../../../redux/store';
import TotalSalesIcon from './components/icons/totalSalesIcon';
import DashboardStatBox from './components/DashboardStatBox';
import {
  getHourlySales,
  getItemWiseSales,
  getPaymentTypes,
  getPickMeOrderCount,
  getTotalNumberOfSales,
  getTotalSaleAmount,
  getUberOrderCount,
  getWalkInCustomerOrderCount,
} from './components/dashboardCalculations';
import { fCurrency } from '../../../utils/formatNumber';
import SalesCountIcon from './components/icons/salesCountIcon';
import WalkingCustomersIcon from './components/icons/walkingCustomersIcon';
import DeliveryIcon from './components/icons/deliveryIcon';
import LineChartComponent from './components/LineChart';
import PieChartComponent from './components/PieChart';
import SalesTargetViewer from './components/SalesTargetViewer';
import BarChartComponent from './components/BarChart';

export default function HomeDashboard() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <Page title='General: Banking'>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <DataFilter data={data} setData={setData} />
          <Grid item xs={12}>
            <SalesTargetViewer title='Sales Target' value={fCurrency(getTotalSaleAmount(data))} icon={<TotalSalesIcon />} shortenNumber={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardStatBox title='Total Sale Amount' value={fCurrency(getTotalSaleAmount(data))} icon={<TotalSalesIcon />} shortenNumber={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardStatBox title='KOT Count' value={getTotalNumberOfSales(data)} icon={<SalesCountIcon />} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DashboardStatBox title='Walk-in Count' value={getWalkInCustomerOrderCount(data)} icon={<WalkingCustomersIcon />} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DashboardStatBox title='Pickme Count' value={getPickMeOrderCount(data)} icon={<DeliveryIcon />} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DashboardStatBox title='Uber Count' value={getUberOrderCount(data)} icon={<DeliveryIcon />} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PieChartComponent title='Payment Types' value={getPaymentTypes(data)} />
          </Grid>
          <Grid item xs={12} md={8}>
            <LineChartComponent title='Hourly Sales Amounts' value={getHourlySales(data)} />
          </Grid>
          <Grid item xs={12} md={8}>
            <BarChartComponent title='Item Count Wise Sales' value={getItemWiseSales(data, 'bar')} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PieChartComponent title='Item Count Wise Sales' value={getItemWiseSales(data, 'pie')} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
