export const userModel = (selectedObject) => {
  return {
    ...selectedObject,
    id: selectedObject?.id || null,
    uId: selectedObject?.uId || null,
    userImage: selectedObject?.userImage || null,
    name: selectedObject?.name || null,
    email: selectedObject?.email || null,
    NIC_Front: selectedObject?.NIC_Front || null,
    NIC_Back: selectedObject?.NIC_Back || null,
    phoneNumber: selectedObject?.phoneNumber || null,
    telephoneNumber: selectedObject?.telephoneNumber || null,
    emergencyContact: selectedObject?.emergencyContact || null,
    userProfileID: selectedObject?.userProfileID || null,
    status: selectedObject?.status || null,
    city: selectedObject?.city || null,
    zipCode: selectedObject?.zipCode || null,
    about: selectedObject?.about || null,
    accountName: selectedObject?.accountName || null,
    bank: selectedObject?.bank || null,
    branch: selectedObject?.branch || null,
    accountNumber: selectedObject?.accountNumber || null,
    daysPerMonth: selectedObject?.daysPerMonth || null,
    rostAbsentDeduction: selectedObject?.rostAbsentDeduction || null,
    timeAllowance: selectedObject?.timeAllowance || null,
    lateCheckingWarningCount: selectedObject?.lateCheckingWarningCount || null,
    basicSalary: selectedObject?.basicSalary || null,
    inspectionEligible: selectedObject?.inspectionEligible || null,
    timeAllowanceEligible: selectedObject?.timeAllowanceEligible || null,
    salesBonusEligible: selectedObject?.salesBonusEligible || null,
    inspectionAllowance: selectedObject?.inspectionAllowance || null,
    workingHoursPerDay: selectedObject?.workingHoursPerDay || null,
    oTpaymentTimes: selectedObject?.oTpaymentTimes || null,
    weekDayCommission: selectedObject?.weekDayCommission || null,
    weekMinimumDayEarningForCommission: selectedObject?.weekMinimumDayEarningForCommission || null,
    weekendDayCommission: selectedObject?.weekendDayCommission || null,
    weekendMinimumDayEarningForCommission: selectedObject?.weekendMinimumDayEarningForCommission || null,
  };
};
