import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { capitalize, safeJSONParse } from '../../../utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import useSettings from '../../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { dispatch, useSelector } from '../../../redux/store';
import DataGridTable from '../../../components/table/DataGridTable';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { Autocomplete, Box, Card, Container, Grid, IconButton, Stack, TextField } from '@mui/material';
import { getAllFoods } from '../../../redux/slices/stockManagementRedux/foodRedux';
import { addCooking } from '../../../redux/slices/cookingRedux/cookingRedux';

export const CookingProcess = () => {
  const { selectedLocation } = useSelector((state) => state.location);
  const { addData } = useSelector((state) => state.cooking);
  const { themeStretch } = useSettings();
  const [rowSelection, setRowSelection] = useState(false);
  const [foodList, setFoodList] = useState([]);
  const [dataList, setDataList] = useState([]);
  let allFoodData;
  ({ allData: allFoodData } = useSelector((state) => state?.food));
  const [data, setData] = useState({
    cookingType: 'dining',
    selectedFood: null,
    unit: 0,
    expiryDate: '2000-01-01',
    barcode: null,
  });

  useEffect(() => {
    setFoodList(
      allFoodData.map((value) => {
        return {
          ...value,
          recipeData: safeJSONParse(value.recipeData),
          pricingData: safeJSONParse(value.pricingData),
          variation: safeJSONParse(value.variation),
          locationData: safeJSONParse(value.locationData),
          unitData: safeJSONParse(value.unitData),
        };
      })
    );
  }, [allFoodData]);

  useEffect(() => {
    dispatch(getAllFoods());
  }, []);

  useEffect(() => {
    if (addData?.data?.status) {
      dispatch(getAllFoods());
      setDataList([]);
      setData({
        cookingType: 'dining',
        selectedFood: null,
        unit: 0,
        expiryDate: '2000-01-01',
        barcode: null,
      });
    }
  }, [addData]);

  const columns = dataList?.[0]
    ? Object.keys(dataList?.[0])
        .map((value) => {
          if (value === 'selectedFood') {
            return {
              accessorKey: 'selectedFood',
              header: 'Food',
              Cell: ({ cell, row }) => {
                return row?.original?.selectedFood?.label;
              },
            };
          } else if (value === 'unit') {
            return {
              accessorKey: 'unit',
              header: 'Unit',
              Cell: ({ cell, row }) => {
                return `${row?.original?.unit} ${row?.original?.selectedFood?.unitData?.[0]?.symbol}`;
              },
            };
          }
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        .filter(Boolean)
    : [];

  return (
    <Page title='Cooking Process for PreCooked'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Cooking Process for PreCooked'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Kitchen', href: PATH_DASHBOARD.kitchen.root }, { name: 'Cooking Process for PreCooked' }]}
          action={[]}
        />
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <DataGridTable
                  name={'Entered Items'}
                  data={dataList}
                  column={columns}
                  isLoading={false}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  isRowClickable={true}
                  enableRowSelection={false}
                  keyField={'stockId'}
                  enablePinning={false}
                  enableRowActions={true}
                  maxHeight={`40vh`}
                  rowsPerPage={8}
                  renderRowActionItems={(row) => {
                    return [
                      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }} key={`view-stock-${row.original.stockId}`}>
                        <IconButton
                          color='primary'
                          onClick={() => {
                            setDataList(dataList.filter((value) => value?.barcode !== row.original.barcode));
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>,
                    ];
                  }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <LoadingButton
                  variant='contained'
                  size='large'
                  style={{ width: '100%' }}
                  onClick={() => {
                    console.log(dataList);
                    dispatch(
                      addCooking({
                        locationId: selectedLocation?.id,
                        data: dataList,
                      })
                    );
                  }}
                >
                  Add Cooked Foods
                </LoadingButton>
                <Card
                  sx={{
                    p: 3,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eee',
                    },
                  }}
                >
                  <Stack spacing={2} sx={{ p: 2 }}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        const foodInfo = foodList.find((value) => value?.id === newValue?.value);
                        setData({
                          ...data,
                          selectedFood: { ...newValue, ...foodInfo },
                        });
                      }}
                      options={foodList
                        ?.filter((value) => ['precooked'].includes(value?.type))
                        .map((value) => ({
                          label: `${value.name}`,
                          value: value.id,
                        }))}
                      value={data?.selectedFood}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField label='Food List' {...params} disabled={dataList?.length > 0} />}
                    />

                    <TextField
                      type='text'
                      label={`Unit ${data?.selectedFood?.unitData?.[0]?.name ? 'in ' + data?.selectedFood?.unitData?.[0]?.name : ''} ${data?.selectedFood?.unitData?.[0]?.symbol ? '(' + data?.selectedFood?.unitData?.[0]?.symbol + ')' : ''}`}
                      sx={{ width: '100%' }}
                      value={data?.unit}
                      onChange={(event) => {
                        setData({ ...data, unit: event.target.value });
                      }}
                    />
                    <TextField type='date' label={`Expiration Date`} value={data?.expiryDate} onChange={(event) => setData({ ...data, expiryDate: event.target.value })} />
                    <TextField
                      type='text'
                      label={`Enter Barcode`}
                      sx={{ width: '100%' }}
                      value={data?.barcode}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.target.select();
                          if (dataList?.map((value) => value?.barcode === event.target.value).includes(true)) {
                            toast.error(`Barcode already exists!`);
                          } else {
                            setDataList([].concat(dataList, [{ ...data, barcode: event.target.value }]));
                          }
                        }
                      }}
                    />
                  </Stack>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};
