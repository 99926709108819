import React, { useEffect, useState } from 'react';
import { Button, Container, Tooltip } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { fCurrency } from '../../../utils/formatNumber';
import { getPurchaseNotes, updatePurchaseNotes } from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import InvoicePDF from '../../../sections/@dashboard/invoice/details/InvoicePDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { COMPANY_DATA_FOR_PDF } from '../../../config';
import { accessVerify, capitalize, handleNumber, safeJSONParse } from '../../../utils/common';

export default function PurchaseNotes() {
  const { themeStretch } = useSettings();
  const [enteredPricing, setEnteredPricing] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);

  const { data, addData } = useSelector((state) => state?.purchaseNotes);

  useEffect(() => {
    dispatch(getPurchaseNotes());
  }, []);

  useEffect(() => {
    setEnteredPricing(data);
  }, [data]);

  useEffect(() => {
    if (addData?.data) {
      dispatch(getPurchaseNotes());
    }
  }, [addData]);

  const getItemsForInvoice = (rowData) => {
    if (rowData) {
      const parsedRowData = safeJSONParse(rowData);

      return parsedRowData?.map((value, index) => {
        const jsonData = safeJSONParse(value?.jsonData);
        const pricePerSupplierUnit = (jsonData?.price / jsonData?.purchaseUnitQty) * jsonData?.storeUnitQty;
        return {
          id: index,
          title: jsonData?.FoodName,
          description: `Final Price is checked on ${jsonData?.formatted_dateTime}.`,
          quantity: jsonData?.purchaseUnitQty,
          price: pricePerSupplierUnit,
        };
      });
    }
  };

  const columns = !enteredPricing?.[0]
    ? []
    : Object.keys(enteredPricing?.[0])
        .map((value) => {
          if (
            value === 'supplierAddress' ||
            value === 'supplierCode' ||
            value === 'supplierCompanyPhone' ||
            value === 'paymentMethod' ||
            value === 'paymentDate' ||
            value === 'estimatedReceivingDate' ||
            value === 'orderedById' ||
            value === 'supplierorder_foodsupplierpricing'
          ) {
            return null;
          } else if (value === 'supplierId') {
            return {
              accessorKey: 'supplierId',
              header: 'Invoice Id',
              Cell: ({ row }) => `INV-${row?.original?.id}`,
            };
          } else if (value === 'totalAmount') {
            return {
              accessorKey: 'totalAmount',
              header: 'Total Amount',
              Cell: ({ row }) => fCurrency(row?.original?.totalAmount),
            };
          } else if (value === 'paidAmount') {
            return {
              accessorKey: 'paidAmount',
              header: 'Paid Amount',
              Cell: ({ row }) => fCurrency(row?.original?.paidAmount),
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        .filter((value) => value);

  return (
    <Page title='Purchase Notes'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Purchase Notes'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Quotation',
              href: PATH_DASHBOARD.stocks.root,
            },
            { name: 'Purchase Notes' },
          ]}
          action={[]}
        />

        {accessVerify('PURCHASE_NOTE_VIEW') && (
          <DataGridTable
            name={'Purchase Notes'}
            data={enteredPricing}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={true}
            renderRowActionItems={(row, closeMenu) => [
              <div style={{ minWidth: '100px' }}>
                <PDFDownloadLink
                  document={
                    <InvoicePDF
                      invoice={{
                        invoiceNumber: `INV-${row?.original?.id}`,
                        createDate: new Date(),
                        dueDate: new Date(new Date().setDate(new Date().getDate() + 30)),
                        status: row?.original?.status,
                        discount: 0,
                        taxes: 0,
                        paidAmount: handleNumber(row?.original?.paidAmount),
                        totalPrice: handleNumber(row?.original?.totalamount) - handleNumber(row?.original?.paidamount),
                        subTotalPrice: handleNumber(row?.original?.totalamount),
                        topic: 'Purchase Order',
                        invoiceFrom: COMPANY_DATA_FOR_PDF,
                        invoiceTo: {
                          name: row?.original?.supplierName,
                          address: row?.original?.supplierAddress,
                          phone: row?.original?.supplierCompanyPhone,
                        },
                        items: getItemsForInvoice(row?.original?.supplierorder_foodsupplierpricing),
                      }}
                    />
                  }
                  fileName={`INV-${row?.original?.id}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Tooltip title='Download PDF'>
                    <Button variant='contained' startIcon={<Iconify icon='eva:download-fill' />} sx={{ padding: '3px 11px' }}>
                      {' '}
                      PDF
                    </Button>
                  </Tooltip>
                </PDFDownloadLink>
                {row?.original?.status === 'DRAFT' && (
                  <Tooltip title='Set Status to Processing / Ordered State'>
                    <Button
                      variant='contained'
                      startIcon={<Iconify icon='eva:check-fill' />}
                      color={'warning'}
                      sx={{ padding: '3px 11px', marginLeft: '3px' }}
                      onClick={() => {
                        dispatch(
                          updatePurchaseNotes({
                            id: row?.original?.id,
                            status: 'PROCESSING',
                          })
                        );
                      }}
                    >
                      {' '}
                      Processing
                    </Button>
                  </Tooltip>
                )}
              </div>,
            ]}
          />
        )}
      </Container>
    </Page>
  );
}
