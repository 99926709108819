import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Chip, Container, Grid, Stack, SwipeableDrawer } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { useTheme } from '@mui/material/styles';
import { getPos, updatePosStatus, viewPOSTab } from '../../../redux/slices/posRedux/posRedux';
import { fCurrency, fCurrencyWithoutSymbol } from '../../../utils/formatNumber';
import { tableCellStyle, tableHeaderStyle, topicStyling } from '../../../utils/cssStyles';
import { capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { handleRePrint } from './components/posReprinter';

export default function SalesList() {
  const theme = useTheme();
  const dataModel = {
    id: null,
    name: null,
    description: null,
  };
  const tableRowStyle = {
    borderBottom: '1px solid #ddd',
  };

  const textTitleStyle = {
    fontSize: '12px',
    color: 'grey',
    marginRight: '10px',
  };

  const textInformationStyle = {
    fontSize: '15px',
    fontWeight: 500,
    paddingLeft: '15px',
  };

  const posLogStyle = {
    fontSize: '11px',
    borderBottom: '1px solid #bbbbbb',
    padding: '3px',
  };

  const hrLine = {
    borderBottom: '1px solid #555555',
    margin: '0px auto 20px auto',
    width: '100%',
  };

  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [foodList, setFoodList] = useState([]);
  const { selectedLocation } = useSelector((state) => state?.location);
  const { data, addData } = useSelector((state) => state.pos);

  useEffect(() => {
    dispatch(getPos(selectedLocation?.id));
  }, [selectedLocation]);

  useEffect(() => {
    setIsModelOpen(false);
    if (data?.length > 0) {
      setFoodList(
        data?.map((value) => {
          return {
            ...value,
            pos_items: safeJSONParse(value?.pos_items),
            pos_payments: safeJSONParse(value?.pos_payments),
            pos_logs: safeJSONParse(value?.pos_logs),
          };
        })
      );
    } else {
      setFoodList([]);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getPos(selectedLocation?.id));
  }, [addData]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setSelectedObject(modelData);
    }
  };

  const getStatusColorCode = (type) => {
    if (type === 'Preparing') {
      return '#CE9600';
    } else if (type === 'Prepared') {
      return '#FF0000';
    } else if (type === 'Served') {
      return '#00921D';
    } else if (type === 'Refunded') {
      return '#FF0000';
    } else {
      return '#585858';
    }
  };

  const columns = [
    {
      accessorKey: 'pos_id',
      header: 'ID',
    },
    {
      accessorKey: 'pos_status',
      header: 'Order Status',
      Cell: ({ cell, row }) => {
        return (
          <Chip
            key={`${row?.original?.id}_${row?.index}`}
            label={row?.original?.pos_status}
            sx={{
              width: '90px',
              m: 0.5,
              backgroundColor: getStatusColorCode(row?.original?.pos_status),
              color: theme.palette.getContrastText(theme.palette.primary.main),
            }}
          />
        );
      },
    },
    {
      accessorKey: 'pos_dateTime',
      header: 'Date & Time',
      Cell: ({ cell, row }) => {
        return <>{utcMoment(row?.original?.pos_dateTime).format('DD MMMM YYYY HH:mm:ss')}</>;
      },
    },
    {
      accessorKey: 'tableNumber',
      header: 'Table Number',
    },
    {
      accessorKey: 'specialNote',
      header: capitalize('specialNote'),
    },
    {
      accessorKey: 'pos_payments',
      header: capitalize('Payment Type'),
      Cell: ({ cell, row }) => {
        return <>{row?.original?.pos_payments?.map((value) => value?.paymentTypesName)}</>;
      },
    },
    {
      accessorKey: 'pos_locationName',
      header: 'Store Location',
    },
    {
      accessorKey: 'customerName',
      header: 'C Name',
    },
    {
      accessorKey: 'customerPhone',
      header: 'C Phone',
    },
    {
      accessorKey: 'customerBirthday',
      header: 'C Birthday',
      Cell: ({ cell, row }) => {
        return <>{utcMoment(row?.original?.customerBirthday).format('DD MMMM')}</>;
      },
    },
  ];

  const [rowSelection, setRowSelection] = useState(false);

  return (
    <Page title={`Pos Sales`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Pos Sales`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Sale',
              href: '',
            },
            { name: `Pos Sales` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                dispatch(viewPOSTab());
              }}
            >
              Create
            </Button>
          }
        />

        <DataGridTable
          name={'Customer List'}
          data={foodList}
          column={columns}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowActions={false}
          enablePinning={false}
          enableRowSelection={false}
          isRowClickable={true}
          onRowClick={(row) => {
            console.log(row);
            manageModel(row?.original);
          }}
          renderRowActionItems={(value, closeMenu) => []}
        />

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ padding: '10px 0 0 10px' }}>
            <h1>
              Order Number {selectedObject?.pos_id} {selectedObject?.tableNumber && <>- Table {selectedObject?.tableNumber}</>}
            </h1>
            <div style={hrLine} />
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ minHeight: '23vh', alignItems: 'center', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Customer Details</p>
                        <div sx={{ flexDirection: 'column' }}>
                          <div sx={{ alignItems: 'center', mb: 2 }}>
                            <table>
                              <tbody>
                                <tr>
                                  <td style={textTitleStyle}>User ID:</td>
                                  <td style={textInformationStyle}>{selectedObject?.pos_customerId}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Date & Time:</td>
                                  <td style={textInformationStyle}>{utcMoment(selectedObject?.pos_dateTime).format('Do MMMM YYYY, h:mm:ss a')}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>POS Created By</td>
                                  <td style={textInformationStyle}>{selectedObject?.pos_entered_userName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Name:</td>
                                  <td style={textInformationStyle}>{selectedObject?.customerName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Phone:</td>
                                  <td style={textInformationStyle}>{selectedObject?.customerPhone}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Birthday:</td>
                                  <td style={textInformationStyle}>{utcMoment(selectedObject?.customerBirthday).format('DD MMMM')}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ minHeight: '23vh', alignItems: 'center', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Drawer Details</p>
                        <div sx={{ flexDirection: 'column' }}>
                          <div sx={{ alignItems: 'center', mb: 2 }}>
                            <table>
                              <tbody>
                                <tr>
                                  <td style={textTitleStyle}>ID:</td>
                                  <td style={textInformationStyle}>{selectedObject?.cashdrawers_id}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Location:</td>
                                  <td style={textInformationStyle}>{selectedObject?.cashdrawers_locationName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Current Status:</td>
                                  <td style={textInformationStyle}>{selectedObject?.cashdrawers_status}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Sale Type:</td>
                                  <td style={textInformationStyle}>{selectedObject?.pos_saleTypeName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Location:</td>
                                  <td style={textInformationStyle}>{selectedObject?.pos_locationName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Special Note:</td>
                                  <td style={textInformationStyle}>{selectedObject?.specialNote}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ minHeight: '33vh', overflowY: 'auto', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Order History</p>
                        <div sx={{ flexDirection: 'column' }}>
                          <div sx={{ alignItems: 'center', mb: 2 }}>
                            <table>
                              <tbody>
                                {selectedObject?.pos_logs?.map((posLogIndex, index) => {
                                  console.log(posLogIndex);
                                  return (
                                    <tr>
                                      <td style={posLogStyle}>{utcMoment(posLogIndex?.dateTime).format('DD/MM HH:mm')}</td>
                                      <td style={posLogStyle}>{posLogIndex?.description}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ minHeight: '33vh', overflowY: 'auto', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Payment Receivings</p>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                          <thead>
                            <tr>
                              <th style={tableHeaderStyle}>Name</th>
                              <th style={tableHeaderStyle}>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedObject?.pos_payments?.map((cartItem) => (
                              <tr key={cartItem?.id} style={tableRowStyle}>
                                <td style={tableCellStyle}>{cartItem?.paymentTypesName}</td>
                                <td style={tableCellStyle}>{fCurrency(cartItem?.amount)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        display: 'flex',
                        alignItems: 'left',
                        flexDirection: 'column',
                        p: 3,
                      }}
                    >
                      <p style={topicStyling}>Order Status</p>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}
                      >
                        {['Preparing', 'Served', 'Refunded', 'Canceled']?.map((value) => (
                          <a
                            key={value}
                            onClick={() => {
                              if (selectedObject?.pos_status === 'Served' && value === 'Preparing') {
                                toast.error('After Served you are not allowed to go to preparing!');
                              } else if (['Refunded', 'Canceled'].includes(selectedObject?.pos_status)) {
                                toast.error(`After ${selectedObject?.pos_status}, You are not eligible to change the status!`);
                              } else if (value === 'Served') {
                                toast.error('Not Allowed to set to served from here. Please try kitchen Display!');
                              } else {
                                dispatch(
                                  updatePosStatus({
                                    id: selectedObject?.pos_id,
                                    status: value,
                                  })
                                );
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <Chip
                              label={value}
                              sx={{
                                bgcolor: value === selectedObject?.pos_status ? getStatusColorCode(value) : '#d3d3d3',
                                color: 'white',
                              }}
                            />
                          </a>
                        ))}
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        display: 'flex',
                        alignItems: 'left',
                        flexDirection: 'column',
                        p: 3,
                      }}
                    >
                      <p style={topicStyling}>Reprint Documents</p>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}
                      >
                        <LoadingButton
                          type='submit'
                          variant='contained'
                          size='large'
                          style={{ width: '48%' }}
                          onClick={() => {
                            handleRePrint({ ...selectedObject, selectedPrinter: localStorage.getItem('selectedPrinter') }, selectedLocation, true);
                          }}
                        >
                          Reprint KOT
                        </LoadingButton>

                        <LoadingButton
                          type='submit'
                          variant='contained'
                          size='large'
                          style={{ width: '48%' }}
                          onClick={() => {
                            handleRePrint({ ...selectedObject, selectedPrinter: localStorage.getItem('selectedPrinter') }, selectedLocation);
                          }}
                        >
                          Reprint Invoice
                        </LoadingButton>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={3} sx={{ p: 2 }}>
                <Card sx={{ minHeight: '60vh', overflowY: 'auto', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <p style={topicStyling}>Ordered Foods</p>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th style={tableHeaderStyle}>Name</th>
                          <th style={tableHeaderStyle}>Qty</th>
                          <th style={tableHeaderStyle}>Unit Price</th>
                          <th style={tableHeaderStyle}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedObject?.pos_items?.map((cartItem) => (
                          <tr key={cartItem?.id} style={tableRowStyle}>
                            <td style={tableCellStyle}>{cartItem?.foodName}</td>
                            <td style={tableCellStyle}>{cartItem?.qty} </td>
                            <td style={tableCellStyle}>{fCurrencyWithoutSymbol(cartItem?.itemPrice)}</td>
                            <td style={tableCellStyle}>{fCurrencyWithoutSymbol(cartItem?.itemPrice * cartItem?.qty)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                </Card>
                <Card style={{ minHeight: '15vh', padding: '20px' }}>
                  <p style={topicStyling}>
                    Customer Paid Amount:{' '}
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 800,
                        textAlign: 'right',
                        color: '#00a372',
                      }}
                    >
                      {fCurrency(selectedObject?.pos_totalPaidAmount)}
                    </span>
                  </p>
                  <p style={topicStyling}>
                    Balance:{' '}
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 800,
                        textAlign: 'right',
                        color: '#00baff',
                      }}
                    >
                      {fCurrency(selectedObject?.pos_balance)}
                    </span>
                  </p>
                  <p style={topicStyling}>
                    Sale Amount:{' '}
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 800,
                        textAlign: 'right',
                        color: 'red',
                      }}
                    >
                      {fCurrency(selectedObject?.pos_totalAmount)}
                    </span>
                  </p>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
