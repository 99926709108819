import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { getStocks } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import { accessVerify, capitalize, safeJSONParse } from '../../../../utils/common';
import moment from 'moment';

const StockList = () => {
  const { themeStretch } = useSettings();
  const [purchaseNotesList, setPurchaseNotesList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);

  const { data: stocks } = useSelector((state) => state?.stock);
  const { selectedLocation } = useSelector((state) => state?.location);

  useEffect(() => {
    dispatch(getStocks(selectedLocation?.id, -1, -1, -1));
  }, [selectedLocation]);

  useEffect(() => {
    setPurchaseNotesList(
      stocks?.map((value) => {
        return {
          ...value,
          date: moment(value?.date).format('DD MMMM YYYY'),
          food: safeJSONParse(value?.foodInfo)?.foodName,
          location: safeJSONParse(value?.locationInfo)?.locationName,
          unit: safeJSONParse(value?.unitInfo)?.unitName,
          supplier: safeJSONParse(value?.supplierInfo)?.supplierName,
          supplierCode: safeJSONParse(value?.supplierInfo)?.supplierCode,
          expireDate: moment(value?.expireDate).format('DD MMMM YYYY'),
        };
      })
    );
  }, [stocks]);

  const columns = purchaseNotesList?.[0]
    ? Object.keys(purchaseNotesList?.[0])
        .map((value) => {
          if (value === 'foodInfo' || value === 'locationInfo' || value === 'supplierInfo' || value === 'unitInfo' || value === 'foodSupplierInfo' || value === 'parentId') {
            return null;
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        .filter(Boolean)
    : [];

  return (
    <Page title='View Stock'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading='View Stock' links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.root }, { name: 'View All' }]} action={[]} />

        {accessVerify('VIEW_STOCK_VIEW') && (
          <DataGridTable
            name={'View Stock'}
            data={purchaseNotesList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            keyField={'stockId'}
            isRowClickable={true}
            onRowClick={(row) => {
              const url = `/dashboard/stock/single-stock-item/${row.original.stockCode}`;
              window.open(url, '_blank', 'noopener,noreferrer');
            }}
            enableRowActions={false}
            renderRowActionItems={(row) => []}
          />
        )}
      </Container>
    </Page>
  );
};

export default StockList;
