import moment from 'moment';

export const getTotalSaleAmount = (data) => {
  return data?.reduce((acc, curr) => acc + curr?.pos_totalAmount, 0) || 0;
};
export const getTotalNumberOfSales = (data) => {
  return data?.length || 0;
};

export const getWalkInCustomerOrderCount = (data) => {
  return data?.filter((value) => [1, 2].includes(value?.pos_saleTypeId))?.length || 0;
};

export const getPickMeOrderCount = (data) => {
  return data?.filter((value) => [3].includes(value?.pos_saleTypeId))?.length || 0;
};
export const getUberOrderCount = (data) => {
  return data?.filter((value) => [4].includes(value?.pos_saleTypeId))?.length || 0;
};

const getDateRangeFromSalesData = (salesData) => {
  const sortedData = salesData.slice().sort((a, b) => new Date(a.pos_dateTime) - new Date(b.pos_dateTime));

  const startDate = moment.utc(sortedData[0]?.pos_dateTime);
  const endDate = moment.utc(sortedData[sortedData.length - 1]?.pos_dateTime);

  const daysDifference = Math.ceil(endDate.diff(startDate, 'hour') / 24);

  return { startDate, endDate, daysDifference };
};
export const getHourlySales = (salesData) => {
  const { startDate, endDate } = getDateRangeFromSalesData(salesData);

  const totalHours = endDate.diff(startDate, 'hours');

  const hourlySales = Array.from({ length: totalHours + 1 }, (_, index) => ({
    dateTime: startDate.clone().add(index, 'hours').format('DD MMMM HH:00'),
    totalAmount: 0,
    totalSales: 0,
  }));

  salesData.forEach((sale) => {
    const saleDateTime = moment.utc(sale?.pos_dateTime);
    const hourIndex = saleDateTime.diff(startDate, 'hours');
    if (hourIndex >= 0 && hourIndex <= totalHours) {
      hourlySales[hourIndex].totalAmount += sale?.pos_totalAmount || 0;
      hourlySales[hourIndex].totalSales += 1;
    }
  });
  return {
    xAxis: hourlySales?.map((value) => value?.dateTime) || [],
    series: [
      {
        label: 'Total Amount',
        data: hourlySales?.map((value) => value?.totalAmount) || [],
      },
    ],
  };
};

export const getPaymentTypes = (data) => {
  const eachPaymentTypeAmounts = [];

  data?.forEach((value) => {
    value?.pos_payments?.forEach((payment) => {
      const paymentType = payment?.paymentTypesName;
      const paymentAmount = payment?.amount;
      const paymentIndex = eachPaymentTypeAmounts.findIndex((value) => value?.paymentType === paymentType);
      if (paymentIndex === -1) {
        eachPaymentTypeAmounts.push({
          paymentType,
          paymentAmount,
        });
      } else {
        eachPaymentTypeAmounts[paymentIndex].paymentAmount += paymentAmount;
      }
    });
  });

  return eachPaymentTypeAmounts?.map((data) => ({ value: data?.paymentAmount, label: data?.paymentType })) || [];
};

export const getItemWiseSales = (data, type) => {
  const eachItemAmounts = [];
  data?.forEach((value) => {
    value?.pos_items?.forEach((item) => {
      const itemName = item?.foodName;
      const itemAmount = item?.qty;
      const itemIndex = eachItemAmounts.findIndex((value) => value?.itemName === itemName);
      if (itemIndex === -1) {
        eachItemAmounts.push({
          itemName,
          itemAmount,
        });
      } else {
        eachItemAmounts[itemIndex].itemAmount += itemAmount;
      }
    });
  });
  const sortedByValue = eachItemAmounts?.map((data) => ({ value: data?.itemAmount, label: data?.itemName }))?.sort((a, b) => b.value - a.value) || [];
  if (type === 'bar') {
    return {
      xAxis: [{ scaleType: 'band', data: sortedByValue?.map((value) => value?.label) || [] }],
      series: [
        {
          label: 'Total Count',
          data: sortedByValue?.map((value) => value?.value) || [],
        },
      ],
    };
  } else if (type === 'pie') {
    return sortedByValue?.map((value) => ({ value: value?.value, label: value?.label })) || [];
  }
};
