import React, { useEffect, useState } from 'react';
import { Box, Card, Chip, Container, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { getStocks } from '../../../redux/slices/stockManagementRedux/stockRedux';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import DataGridTable from '../../../components/table/DataGridTable';
import { capitalize, safeJSONParse } from '../../../utils/common';
import { addStockTransferSender, updateLatestAvailableTime } from '../../../redux/slices/stockManagementRedux/stockTransferRedux';
import { toast } from 'react-toastify';

const StockAvailabilityChecker = () => {
  const { themeStretch } = useSettings();
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState();

  const { data: stockData, addData } = useSelector((state) => state?.stock);

  useEffect(() => {
    if (data?.barcode?.length > 0) {
      dispatch(getStocks(-1, -1, -1, data?.barcode));
    }
  }, [data?.barcode]);

  return (
    <Page title='Availability Checker'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Availability Checker'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.transfer.sender }, { name: 'Availability Checker' }]}
          action={[]}
        />
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card
                  sx={{
                    p: 3,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eee',
                    },
                  }}
                >
                  <TextField
                    type='text'
                    label={`Enter Barcode`}
                    sx={{ width: '100%' }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.target.select();
                        dispatch(
                          updateLatestAvailableTime({
                            code: event.target.value,
                          })
                        );
                        setData(event.target.value);
                      }
                    }}
                  />
                  <br />
                  <br />

                  <h2 style={{ textAlign: 'center' }}>Latest Updated Code Is</h2>
                  <h1 style={{ textAlign: 'center' }}>{data}</h1>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default StockAvailabilityChecker;
