import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Chip, Container, Grid, Stack, SwipeableDrawer, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { useTheme } from '@mui/material/styles';
import { getPos, updatePosStatus, viewPOSTab } from '../../../redux/slices/posRedux/posRedux';
import moment from 'moment';
import { fCurrency, fCurrencyWithoutSymbol } from '../../../utils/formatNumber';
import { tableCellStyle, tableHeaderStyle, topicStyling } from '../../../utils/cssStyles';
import { capitalize, safeJSONParse } from '../../../utils/common';
import { toast } from 'react-toastify';
import { addLoyaltyRecord, getCustomers } from '../../../redux/slices/customerRedux/customerRedux';

export default function CustomerList() {
  const theme = useTheme();
  const dataModel = {
    id: null,
    name: null,
    description: null,
  };
  const tableRowStyle = {
    borderBottom: '1px solid #ddd',
  };

  const textTitleStyle = {
    fontSize: '12px',
    color: 'grey',
    marginRight: '10px',
  };

  const textInformationStyle = {
    fontSize: '15px',
    fontWeight: 500,
    paddingLeft: '15px',
  };

  const posLogStyle = {
    fontSize: '11px',
    borderBottom: '1px solid #bbbbbb',
    padding: '3px',
  };

  const hrLine = {
    borderBottom: '1px solid #555555',
    margin: '0px auto 20px auto',
    width: '100%',
  };

  const getOrderStatusStyling = (status) => ({
    fontSize: '30px',
    margin: '20px',
    fontWeight: 800,
    textAlign: 'center',
    color: 'white',
    backgroundColor: status === selectedObject?.pos_status ? getStatusColorCode(status) : '#d3d3d3',
    padding: '3px 20px',
    borderRadius: '25px',
    cursor: 'pointer',
    transition: 'background-color 0.4s',
    '&:hover': {
      backgroundColor: '#a1a1a1 !important',
    },
  });

  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const { selectedLocation } = useSelector((state) => state?.location);
  const { allData, addData } = useSelector((state) => state.customers);
  const [loyaltyPointData, setLoyaltyPointData] = useState({ amount: null });

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  useEffect(() => {
    setIsModelOpen(false);
    if (allData?.length > 0) {
      setCustomerList(
        allData?.map((value) => {
          return {
            ...value,
            loyalty_data: safeJSONParse(value?.loyalty_data),
            pos_data: safeJSONParse(value?.pos_data),
          };
        })
      );
    } else {
      setCustomerList([]);
    }
  }, [allData]);

  useEffect(() => {
    dispatch(getCustomers());
  }, [addData]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setSelectedObject(modelData);
    }
  };

  const getStatusColorCode = (type) => {
    if (type === 'Preparing') {
      return '#CE9600';
    } else if (type === 'Prepared') {
      return '#FF0000';
    } else if (type === 'Served') {
      return '#00921D';
    } else if (type === 'Refunded') {
      return '#FF0000';
    } else {
      return '#585858';
    }
  };

  const columns = [
    {
      accessorKey: 'customer_id',
      header: 'ID',
    },
    {
      accessorKey: 'customer_name',
      header: 'Customer Name',
    },
    {
      accessorKey: 'customer_phone',
      header: 'Phone',
    },
    {
      accessorKey: 'customer_datetime',
      header: 'Join Date',
      Cell: ({ cell, row }) => {
        return <>{moment(row?.original?.pos_dateTime).format('DD MMMM YYYY')}</>;
      },
    },
    {
      accessorKey: 'customer_birthday',
      header: 'Birthday',
      Cell: ({ cell, row }) => {
        return <>{moment(row?.original?.pos_dateTime).format('DD MMMM')}</>;
      },
    },
    {
      accessorKey: 'total_amount',
      header: 'Loyalty Amount',
      Cell: ({ cell, row }) => {
        return <>{fCurrency(row?.original?.total_amount)}</>;
      },
    },
  ];

  const [rowSelection, setRowSelection] = useState(false);
  return (
    <Page title={`Customer List`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Customer List`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Sale',
              href: '',
            },
            { name: `Customer List` },
          ]}
          action={<></>}
        />

        <DataGridTable
          name={'Pos Sales'}
          data={customerList}
          column={columns}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowActions={false}
          enablePinning={false}
          enableRowSelection={false}
          isRowClickable={true}
          onRowClick={(row) => {
            manageModel(row?.original);
          }}
          renderRowActionItems={(value, closeMenu) => []}
        />

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ padding: '10px 0 0 10px' }}>
            <h1>Customer Details</h1>
            <div style={hrLine} />
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ height: '33vh', overflowY: 'auto', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>POS History</p>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                          <thead>
                            <tr>
                              <th style={tableHeaderStyle}>Id</th>
                              <th style={tableHeaderStyle}>Date Time</th>
                              <th style={tableHeaderStyle}>status</th>
                              <th style={tableHeaderStyle}>Cash Drawer ID</th>
                              <th style={tableHeaderStyle}>Total Amount</th>
                              <th style={tableHeaderStyle}>Total Paid Amount</th>
                              <th style={tableHeaderStyle}>Balance Amount</th>
                              <th style={tableHeaderStyle}>Added By</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedObject?.pos_data?.map((posData, index) => (
                              <tr key={posData?.pos_id} style={tableRowStyle}>
                                <td style={tableCellStyle}>{posData?.id}</td>
                                <td style={tableCellStyle}>{moment(posData?.datetime).format('DD/MM/YYYY HH:mm')}</td>
                                <td style={tableCellStyle}>{posData?.status}</td>
                                <td style={tableCellStyle}>{posData?.cash_drawers_id}</td>
                                <td style={tableCellStyle}>{fCurrency(posData?.total_amount)}</td>
                                <td style={tableCellStyle}>{fCurrency(posData?.total_paid_amount)}</td>
                                <td style={tableCellStyle}>{fCurrency(posData?.balance)}</td>
                                <td style={tableCellStyle}>{posData?.userName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ height: '33vh', overflowY: 'auto', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Loyalty History</p>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                          <thead>
                            <tr>
                              <th style={tableHeaderStyle}>Id</th>
                              <th style={tableHeaderStyle}>Date</th>
                              <th style={tableHeaderStyle}>Title</th>
                              <th style={tableHeaderStyle}>Amount</th>
                              <th style={tableHeaderStyle}>Added By</th>
                              <th style={tableHeaderStyle}>Validity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedObject?.loyalty_data?.map((loyaltyData, index) => (
                              <tr key={loyaltyData?.pos_id} style={tableRowStyle}>
                                <td style={tableCellStyle}>{loyaltyData?.id}</td>
                                <td style={tableCellStyle}>{moment(loyaltyData?.datetime).format('DD/MM/YYYY HH:mm')}</td>
                                <td style={tableCellStyle}>{loyaltyData?.title}</td>
                                <td style={tableCellStyle}> {fCurrency(loyaltyData?.amount)} </td>
                                <td style={tableCellStyle}> {loyaltyData?.userName} </td>
                                <td style={tableCellStyle}> {loyaltyData?.isValid === 1 ? 'Valid' : 'Removed'} </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={3} sx={{ p: 2 }}>
                <Card style={{ height: '25vh', padding: '20px' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <p style={topicStyling}>Manage Loyalty Points</p>
                    <Stack spacing={3} sx={{ p: 2 }}>
                      <TextField
                        type='number'
                        label={`Amount`}
                        value={loyaltyPointData?.amount || ''}
                        onChange={(event) => {
                          setLoyaltyPointData({
                            ...loyaltyPointData,
                            amount: event.target.value,
                          });
                        }}
                        onClick={(event) => {
                          event.target.select();
                        }}
                      />
                      <Button
                        variant='contained'
                        startIcon={<Iconify icon='eva:plus-fill' />}
                        onClick={() => {
                          if (loyaltyPointData?.amount) {
                            dispatch(
                              addLoyaltyRecord({
                                customerId: selectedObject?.customer_id,
                                amount: loyaltyPointData?.amount,
                                title: 'Manual Add',
                              })
                            );
                            setLoyaltyPointData({
                              ...loyaltyPointData,
                              amount: null,
                            });
                          } else {
                            toast.error('Please enter amount');
                          }
                        }}
                      >
                        Add Amount & Close
                      </Button>
                    </Stack>
                  </Box>
                </Card>
                <Card sx={{ height: '23vh', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <p style={topicStyling}>Customer Details</p>
                    <div sx={{ flexDirection: 'column' }}>
                      <div sx={{ alignItems: 'center', mb: 2 }}>
                        <table>
                          <tbody>
                            <tr>
                              <td style={textTitleStyle}>Loyalty Amount</td>
                              <td style={textInformationStyle}>{fCurrency(selectedObject?.total_amount)}</td>
                            </tr>
                            <tr>
                              <td style={textTitleStyle}>Joining Date Time</td>
                              <td style={textInformationStyle}>{moment(selectedObject?.customer_datetime).format('DD MMMM YYYY')}</td>
                            </tr>
                            <tr>
                              <td style={textTitleStyle}>Birthday</td>
                              <td style={textInformationStyle}>{moment(selectedObject?.customer_birthday).format('DD MMMM')}</td>
                            </tr>
                            <tr>
                              <td style={textTitleStyle}>Name</td>
                              <td style={textInformationStyle}>{selectedObject?.customer_name}</td>
                            </tr>
                            <tr>
                              <td style={textTitleStyle}>Phone</td>
                              <td style={textInformationStyle}>{selectedObject?.customer_phone}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Box>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
