import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logoSize = 200;

  const logo = (
    <Box sx={{ width: logoSize, height: logoSize, ...sx }}>
      <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={sx.width} height={sx.height}>
        <image
          width={sx.width}
          height={sx.height}
          href={'https://res.cloudinary.com/dxsebxmmc/image/upload/w_500/v1712285366/cpbp6oggxofoqlcganmp.png'}
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to='/'>{logo}</RouterLink>;
}
