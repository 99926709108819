import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Chip, Container, Grid, IconButton, List, ListItem, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { getAllAssets } from '../../../redux/slices/assetsRedux/assetsRedux';
import { toast } from 'react-toastify';
import { capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import { CASHFLOW_PAYMENT_STATUS, CASHFLOW_TYPES } from '../../../utils/constants';
import DatePicker from '@mui/lab/DatePicker';
import { getPurchaseNotes } from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import { addCashFlows, getCashFlows, updateCashFlows } from '../../../redux/slices/financeRedux/cashFlowRedux';
import { fCurrency } from '../../../utils/formatNumber';
import { getPaymentTypes } from '../../../redux/slices/financeRedux/paymentTypeRedux';
import UploadMultiFile from '../../../components/upload/UploadMultiFile';
import { imageUpload } from '../../../inteceptor';
import ImageViewer from 'react-simple-image-viewer';
import { AnimatePresence, m } from 'framer-motion';
import Image from '../../../components/Image';
import { alpha } from '@mui/material/styles';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

export default function Cashflow() {
  const { themeStretch } = useSettings();

  let locationList;
  let selectedLocation;
  ({ data: locationList, selectedLocation } = useSelector((state) => state.location));

  const dataModel = {
    id: null,
    isDebit: null,
    dateTime: null,
    paymentDate: null,
    paidDate: null,
    title: '',
    description: '',
    type: 1,
    paymentTypeId: 1,
    status: null,
    amount: 0,
    purchaseNoteId: null,
    posCashDrawersID: null,
    assetId: null,
    userId: null,
    locationId: selectedLocation?.id,
    linkedDocuments: [],
    isTransfer: 0,
    transferId: null,
    cashflowCategoriesId: null,
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [cashflowList, setCashflowList] = useState([]);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [documentGallery, setDocumentGallery] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const { data, addData } = useSelector((state) => state.cashFlow);
  const { loggedInUser } = useSelector((state) => state?.user);

  let cashFlowCategories;
  ({ data: cashFlowCategories } = useSelector((state) => state.cashFlowCategories));

  let purchaseNoteList;
  ({ data: purchaseNoteList } = useSelector((state) => state.purchaseNotes));

  let assetsList;
  ({ allData: assetsList } = useSelector((state) => state.assets));

  let tempPaymentTypeList;
  ({ data: tempPaymentTypeList } = useSelector((state) => state.paymentTypes));

  useEffect(() => {
    setPaymentTypeList(tempPaymentTypeList?.filter((value) => value?.isCashFlowOption === 1));
  }, [tempPaymentTypeList]);

  useEffect(() => {
    dispatch(getCashFlows(selectedLocation?.id));
  }, [selectedLocation]);

  useEffect(() => {
    dispatch(getPaymentTypes());
    dispatch(getAllAssets());
    dispatch(getPurchaseNotes());
  }, []);

  useEffect(() => {
    setCashflowList(
      data?.map((value) => {
        return {
          ...value,
          linkedInfo: safeJSONParse(value?.linkedInfo),
          originalStatus: value?.status,
          linkedDocuments: safeJSONParse(value?.linkedInfo)?.cashFlowDocuments?.filter((value) => value?.url) || [],
          cashFlowLog: safeJSONParse(value?.linkedInfo)?.cashFlowLog?.filter((value) => value?.title) || [],
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsViewerOpen(false);
      setIsModelOpen(false);
      setSelectedDataObj(dataModel);
      setDocumentGallery([]);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      setIsViewerOpen(false);
      if (type === 'add') {
        setDocumentGallery([]);
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getCashFlows(selectedLocation?.id));
    }
  }, [addData]);

  useEffect(() => {
    setDocumentGallery(selectedDataObj?.linkedDocuments?.map((value) => value?.url));
  }, [selectedDataObj]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !cashflowList?.[0]
    ? []
    : Object.keys(cashflowList?.[0])
        .map((value) => {
          if (['linkedInfo', 'linkedDocuments', 'cashFlowLog'].includes(value)) {
            return {};
          } else if (['isDebit'].includes(value)) {
            return {
              accessorKey: 'isDebit',
              header: 'Debit/Credit',
              Cell: ({ cell, row }) => {
                const rowValue = row?.original?.isDebit;
                return <Chip key={`${row?.original?.id}_${row?.index}`} label={rowValue ? 'Debit' : 'Credit'} color={rowValue ? 'success' : 'error'} />;
              },
            };
          } else if (['dateTime', 'paymentDate', 'paidDate'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                const rowValue = row?.original?.[value];
                const isValidDate = utcMoment(rowValue).isValid();
                return isValidDate ? utcMoment(rowValue).format('YYYY-MM-DD HH:mm:ss') : 'Not Paid Yet';
              },
            };
          } else if (['status'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                const rowValue = row?.original?.[value];
                const status = CASHFLOW_PAYMENT_STATUS?.find((value) => value?.id === rowValue)?.name;
                return <Chip key={`${row?.original?.id}_${row?.index}`} label={status} color={['Paid'].includes(status) ? 'success' : ['Pending'].includes(status) ? 'warning' : 'error'} />;
              },
            };
          } else if (['userId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Added User',
              Cell: ({ cell, row }) => {
                return row?.original?.linkedInfo?.userData?.[0]?.name || 'Not Found';
              },
            };
          } else if (['type'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return row?.original?.type ? CASHFLOW_TYPES?.find((value) => value?.id === row?.original?.type)?.name : 'Not Found';
              },
            };
          } else if (['assetId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Asset',
              Cell: ({ cell, row }) => {
                return row?.original?.linkedInfo?.asset?.id ? `${row?.original?.linkedInfo?.asset?.id} - ${row?.original?.linkedInfo?.asset?.name}` : 'Not Found';
              },
            };
          } else if (['amount'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return fCurrency(row?.original?.[value]);
              },
            };
          } else if (['purchaseNoteId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Purchase Note Id',
              Cell: ({ cell, row }) => {
                return row?.original?.purchaseNoteId ? `${row?.original?.purchaseNoteId}` : 'Not Found';
              },
            };
          } else if (['cashflowCategoriesId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'CashFlow Category',
              Cell: ({ cell, row }) => {
                return cashFlowCategories?.find((value) => value?.id === row?.original?.cashflowCategoriesId)?.name || '-';
              },
            };
          } else if (['locationId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Location',
              Cell: ({ cell, row }) => {
                return row?.original?.linkedInfo?.locationData?.[0] ? `${row?.original?.linkedInfo?.locationData?.[0]?.name}` : 'Not Found';
              },
            };
          } else if (['paymentTypeId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Transactional mediums',
              Cell: ({ cell, row }) => {
                return row?.original?.linkedInfo?.cashFlowPaymentType?.[0] ? `${row?.original?.linkedInfo?.cashFlowPaymentType?.[0]?.name}` : 'Not Found';
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    if (selectedDataObj.title?.length === 0) {
      toast.error('Title is required!');
    } else if (selectedDataObj.description?.length === 0) {
      toast.error('Description is required!');
    } else if (selectedDataObj.amount === 0) {
      toast.error('Amount is required!');
    } else if (selectedDataObj.type === null) {
      toast.error('Type is required!');
    } else if (files?.length > 0) {
      toast.error('Related documents needs to be uploaded first!');
    } else {
      dispatch(isAdd ? addCashFlows(selectedDataObj) : updateCashFlows(selectedDataObj));
    }
  };

  const uploadFiles = (file) => {
    return new Promise((resolve, reject) => {
      imageUpload(
        file,
        `Finance/Cashflow/Loc_${selectedLocation?.id}/User_${loggedInUser?.id}`,
        (data) => {
          if (data?.status) {
            resolve(data);
          } else {
            reject(new Error('Upload failed'));
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
          reject(new Error(errorMessage));
        }
      );
    });
  };

  const handleImageUpload = async () => {
    setIsUploading(true);
    for (const file of files) {
      try {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: true,
              };
            }
            return fileValue;
          });
        });

        const data = await uploadFiles(file);

        setFiles((value) => {
          return value.filter((fileValue, index) => file?.id !== fileValue?.id);
        });
        if (data?.status) {
          setSelectedDataObj((prevState) => ({
            ...prevState,
            linkedDocuments: [...prevState.linkedDocuments, { name: file?.name, url: data?.url }],
          }));
        }
      } catch (error) {
        console.error(error);
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: false,
              };
            }
            return fileValue;
          });
        });
      }
    }
    setIsUploading(false);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Page title={`Cashflow`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Cashflow`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Finance',
              href: '',
            },
            { name: `Cashflow` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Record
            </Button>
          }
        />

        <DataGridTable
          name={'Assets List'}
          data={cashflowList}
          column={[...columns]}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowSelection={false}
          enableRowActions={false}
          isRowClickable={true}
          onRowClick={(row) => {
            manageModel(row?.original, 'edit');
          }}
          renderRowActionItems={(value, closeMenu) => []}
        />

        <SwipeableDrawer
          anchor={'bottom'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '95%',
              height: '95%',
              marginLeft: '2.5%',
              borderRadius: '10px',
            },
          }}
        >
          <Grid container spacing={1} padding={2}>
            <Grid item xs={12} md={8}>
              <Box sx={{ p: 2 }}>
                <h1>{isAdd ? 'Add' : 'Edit'} Record</h1>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color='info'
                variant='outlined'
                size='large'
                style={{ width: '100%' }}
                onClick={() => {
                  manageModel(dataModel, 'add');
                }}
              >
                Close
              </Button>
              <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%', marginLeft: '8px' }} onClick={handleSubmit}>
                {isAdd ? 'Add Record' : 'Save Changes'}
              </LoadingButton>
            </Grid>

            <Grid item xs={12} md={6} lg={selectedDataObj?.cashFlowLog?.length > 0 ? 4 : 6}>
              <Card sx={{ p: 3 }}>
                <Typography variant='subtitle1' padding='10px 0'>
                  Payment Information
                </Typography>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          if (isAdd) {
                            updateEditingData('paymentTypeId', paymentTypeList.find((value) => value?.id === newValue?.value)?.id);
                          }
                        }}
                        options={paymentTypeList?.map((value) => ({
                          label: `${value?.name}`,
                          value: value?.id,
                        }))}
                        value={
                          paymentTypeList?.find((value) => value.id === selectedDataObj?.paymentTypeId)?.name && {
                            label: `${paymentTypeList?.find((value) => value.id === selectedDataObj?.paymentTypeId)?.name || 'Transactional mediums'}`,
                            value: selectedDataObj?.type,
                          }
                        }
                        renderInput={(params) => <TextField label='Select Transactional mediums' {...params} />}
                        disabled={!isAdd}
                      />
                      <Autocomplete
                        onChange={(event, newValue) => {
                          if (isAdd) {
                            updateEditingData('type', CASHFLOW_TYPES.find((value) => value?.id === newValue?.value)?.id);
                          }
                        }}
                        options={CASHFLOW_TYPES.filter((value) => value?.isShow)?.map((value) => ({
                          label: `${value?.name}`,
                          value: value?.id,
                        }))}
                        value={
                          CASHFLOW_TYPES?.find((value) => value.id === selectedDataObj?.type)?.name && {
                            label: `${CASHFLOW_TYPES?.find((value) => value.id === selectedDataObj?.type)?.name || 'Payment Type'}`,
                            value: selectedDataObj?.type,
                          }
                        }
                        renderInput={(params) => <TextField label='Select Payment Type' {...params} />}
                        disabled={!isAdd}
                      />
                      <TextField fullWidth label='Title' value={selectedDataObj?.title} onChange={(e) => updateEditingData('title', e.target.value)} />

                      <TextField fullWidth label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />

                      {selectedDataObj?.type === 3 && (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            if (isAdd) {
                              updateEditingData('purchaseNoteId', purchaseNoteList.find((value) => value?.id === newValue?.value)?.id);
                            }
                          }}
                          options={purchaseNoteList?.map((value) => ({
                            label: `${value?.id} - (${value?.supplierName} ${value?.supplierCode} - ${value?.supplierCompanyPhone}) `,
                            value: value?.id,
                          }))}
                          value={
                            purchaseNoteList?.find((value) => value.id === selectedDataObj?.purchaseNoteId)?.id && {
                              label: `Note Id: ${purchaseNoteList?.find((value) => value.id === selectedDataObj?.purchaseNoteId)?.id || 'Purchase Note is not found'}`,
                              value: selectedDataObj?.id,
                            }
                          }
                          renderInput={(params) => <TextField label='Select Purchase Note' {...params} />}
                          disabled={!isAdd}
                        />
                      )}

                      {selectedDataObj?.type === 2 && (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            if (isAdd) {
                              updateEditingData('assetId', assetsList.find((value) => value?.id === newValue?.value)?.id);
                            }
                          }}
                          options={assetsList?.map((value) => ({
                            label: `${value?.name}`,
                            value: value?.id,
                          }))}
                          value={
                            assetsList?.find((value) => value.id === selectedDataObj?.assetId)?.name && {
                              label: `${assetsList?.find((value) => value.id === selectedDataObj?.assetId)?.name || 'Purchase Note is not found'}`,
                              value: selectedDataObj?.id,
                            }
                          }
                          renderInput={(params) => <TextField label='Select Linked Asset' {...params} />}
                          disabled={!isAdd}
                        />
                      )}

                      <Autocomplete
                        onChange={(event, newValue) => {
                          updateEditingData('cashflowCategoriesId', cashFlowCategories.find((value) => value?.id === newValue?.value)?.id);
                        }}
                        options={cashFlowCategories?.map((value) => ({
                          label: `${value?.name}`,
                          value: value?.id,
                        }))}
                        value={{
                          label: `${cashFlowCategories?.find((value) => value.id === selectedDataObj?.cashflowCategoriesId)?.name || 'Cashflow Categories'}`,
                          value: selectedDataObj?.cashflowCategoriesId,
                        }}
                        renderInput={(params) => <TextField label='Cashflow Categories' {...params} />}
                      />

                      <Autocomplete
                        onChange={(event, newValue) => {
                          if (selectedDataObj?.status !== 3) {
                            updateEditingData('status', CASHFLOW_TYPES.find((value) => value?.id === newValue?.value)?.id);
                          }
                        }}
                        options={CASHFLOW_PAYMENT_STATUS?.filter((value) => (value?.visibleOnAdd && isAdd) || (value?.visibleOnEdit && !isAdd))?.map((value) => ({
                          label: `${value?.name}`,
                          value: value?.id,
                        }))}
                        value={{
                          label: `${CASHFLOW_PAYMENT_STATUS?.find((value) => value.id === selectedDataObj?.status)?.name || 'Payment Status'}`,
                          value: selectedDataObj?.status,
                        }}
                        renderInput={(params) => <TextField label='Payment Status' {...params} />}
                        disabled={selectedDataObj?.originalStatus === 3}
                      />

                      {selectedDataObj?.status === 2 && (
                        <DatePicker
                          label='Payment Date'
                          value={selectedDataObj?.paymentDate}
                          onChange={(newValue) => {
                            updateEditingData('paymentDate', newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}

                      <TextField
                        fullWidth
                        type='number'
                        label='Amount'
                        value={selectedDataObj?.amount}
                        onChange={(e) => {
                          if (isAdd) {
                            updateEditingData('amount', e.target.value);
                          }
                        }}
                        disabled={!isAdd}
                      />
                    </Stack>
                  </Card>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={selectedDataObj?.cashFlowLog?.length > 0 ? 4 : 6}>
              <Card sx={{ p: 3 }}>
                <Typography variant='subtitle1' padding='10px 0'>
                  Related Documents
                </Typography>
                <UploadMultiFile
                  error={false}
                  showPreview={true}
                  files={files}
                  onRemove={(removedFile) => {
                    setFiles(files.filter((file) => file !== removedFile));
                  }}
                  onRemoveAll={() => {
                    setFiles([]);
                  }}
                  helperText={''}
                  onDrop={(uploadedFiles) => {
                    setFiles((value) => [
                      ...value,
                      ...uploadedFiles.map((file, index) =>
                        Object.assign(file, {
                          id: index,
                          preview: URL.createObjectURL(file),
                          isUploading: false,
                        })
                      ),
                    ]);
                  }}
                  onUpload={() => {
                    handleImageUpload();
                  }}
                  isUploading={isUploading}
                />

                {documentGallery?.length > 0 && (
                  <Typography variant='subtitle1' padding='10px 0'>
                    Uploaded Images
                  </Typography>
                )}
                <List disablePadding sx={{ my: 3 }}>
                  <AnimatePresence>
                    {documentGallery.map((src, index) => (
                      <>
                        <ListItem
                          key={index}
                          component={m.div}
                          onClick={() => {
                            openImageViewer(index);
                          }}
                          sx={{
                            p: 0,
                            m: 0.5,
                            width: 80,
                            height: 80,
                            borderRadius: 1.25,
                            overflow: 'hidden',
                            position: 'relative',
                            cursor: 'pointer',
                            display: 'inline-flex',
                            border: (theme) => `solid 1px ${theme.palette.divider}`,
                          }}
                        >
                          <Image alt='preview' src={src} ratio='1/1' />
                          <IconButton
                            size='small'
                            sx={{
                              top: 6,
                              p: '2px',
                              right: 6,
                              position: 'absolute',
                              color: 'common.white',
                              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                              '&:hover': {
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                              },
                            }}
                          ></IconButton>
                        </ListItem>
                      </>
                    ))}
                  </AnimatePresence>
                </List>
              </Card>
            </Grid>

            {selectedDataObj?.cashFlowLog?.length > 0 && (
              <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <Typography variant='subtitle1' sx={{ p: 3 }}>
                    History Information
                  </Typography>
                  <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <Timeline position='alternate'>
                      {selectedDataObj?.cashFlowLog?.map((value) => (
                        <TimelineItem key={value.id}>
                          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                            {utcMoment(value?.dateTime).format('YYYY-MM-DD HH:mm:ss')}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                              <Iconify icon='eva:checkmark-circle-2-fill' />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant='body1' component='span'>
                              <b>{value?.title}</b>
                            </Typography>
                            <Typography>{value?.description}</Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  </div>
                </Card>
              </Grid>
            )}

            {isViewerOpen && <ImageViewer src={documentGallery} currentIndex={currentImage} disableScroll={false} closeOnClickOutside={true} onClose={closeImageViewer} />}
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
