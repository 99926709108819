import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
import { fShortenNumber } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3),
}));

// ----------------------------------------------------------------------

DashboardStatBox.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default function DashboardStatBox({ title, value, icon, shortenNumber = false }) {
  return (
    <RootStyle>
      <div>
        {!shortenNumber && <Typography variant='h3'>{value}</Typography>}
        {shortenNumber && <Typography variant='h3'>{fShortenNumber(value)}</Typography>}
        <Typography variant='h6' sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
      </div>
      {icon}
    </RootStyle>
  );
}
