import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../store';
import { deleteMethod, get, post, put } from '../../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  loggedInAccessControl: [],
  selectedAccessControl: [],
  currentAccessControl: [],
  documentTypes: [],
  documents: [],
  addData: {},
  sorted: [],
};

const slice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    // GET AccessControlManagement
    getAccessControlSuccess(state, action) {
      state.data = action.payload.data;
    },
    // GET AccessControlManagement
    updateCurrentLoggedInAccessControlData(state, action) {
      state.loggedInAccessControl = action.payload;
    },

    // GET AccessControlManagement
    getCurrentAccessControlSuccess(state, action) {
      state.currentAccessControl = action.payload.data;
      state.documentTypes = action.payload.documentTypes;
      state.documents = action.payload.documents;
    },

    // GET AccessControlManagement
    getSpecificAccessControlDataSuccess(state, action) {
      state.selectedAccessControl = action.payload.data;
      state.documentTypes = action.payload.documentTypes;
      state.documents = action.payload.documents;
    },

    addOrUpdateAccessControls(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getAccessControl() {
  return async () => {
    try {
      const response = await get(`accessControl`);
      if (response.status) {
        dispatch(slice.actions.getAccessControlSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccessControlById(userId) {
  return async () => {
    try {
      const response = await get(`accessControlById?id=${userId}`);
      if (response.status) {
        dispatch(slice.actions.getAccessControlSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function generateAccessControl(body) {
  return async () => {
    try {
      const response = await post(`generateAccessControl?date=${body?.month}`);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateAccessControls(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAccessControl(body) {
  return async () => {
    try {
      const response = await post(`AccessControl`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateAccessControls(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateAccessControl(body) {
  return async () => {
    try {
      const response = await put(`AccessControl`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateAccessControls(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteAccessControl(body) {
  return async () => {
    try {
      const response = await deleteMethod(`AccessControl`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateAccessControls(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
