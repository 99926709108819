import { createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../../../config';

const initialState = {
  isLoading: false,
  loadingNumber: 0,
};

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.loadingNumber = state.loadingNumber + 1;
    },

    // Done LOADING
    doneLoading(state) {
      state.isLoading = false;
      state.loadingNumber = state.loadingNumber - 1;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

export function startLoading() {
  return async () => {
    if (!BASE_URL.includes('localhost') && !BASE_URL.includes('dev')) {
      // dispatch(slice.actions.startLoading());
    }
  };
}

export function doneLoading() {
  return async () => {
    if (!BASE_URL.includes('localhost') && !BASE_URL.includes('dev')) {
      // dispatch(slice.actions.doneLoading());
    }
  };
}
