import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { getSuppliers } from '../../../redux/slices/stockManagementRedux/supplierRedux';
import { getAllFoods } from '../../../redux/slices/stockManagementRedux/foodRedux';
import { getUnits } from '../../../redux/slices/stockManagementRedux/unitRedux';
import { getUsers } from '../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { addEnterPrice, getEnterPrices } from '../../../redux/slices/purchaseRedux/purchaseRedux';
import { fCurrency } from '../../../utils/formatNumber';
import { accessVerify, capitalize, safeJSONParse } from '../../../utils/common';

export default function EnterPrices() {
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enteredPricing, setEnteredPricing] = useState([]);
  const [dataModel, setDataModel] = useState({
    supplier: null,
  });

  const { data, addData } = useSelector((state) => state?.enterPrices);
  let allFoodData;
  ({ allData: allFoodData } = useSelector((state) => state?.food));
  let units;
  ({ data: units } = useSelector((state) => state?.unit));
  let supplierData;
  ({ data: supplierData } = useSelector((state) => state?.supplier));

  useEffect(() => {
    dispatch(getEnterPrices());
    dispatch(getSuppliers(1));
    dispatch(getAllFoods());
    dispatch(getUsers());
    dispatch(getUnits());
  }, []);

  useEffect(() => {
    setEnteredPricing(data);
    setIsModelOpen(false);
  }, [data]);

  const updateDataModel = (key, value) => {
    setDataModel((prevDataModel) => ({
      ...prevDataModel,
      [key]: value,
    }));
  };

  const manageModel = (type) => {
    setDataModel({ supplier: null });
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      if (type === 'add') {
        setIsModelOpen(true);
        setIsAdd(type === 'add');
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (isAdd) {
        const priceList = {};
        Object.entries(dataModel).forEach(([key, value]) => {
          if (key.includes('foodPrice_Id_') && Number(value) > 0) {
            const newKey = key.replace('foodPrice_Id_', '');
            priceList[newKey] = value;
          }
        });
        dispatch(addEnterPrice({ ...dataModel, priceList: priceList }));
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getEnterPrices());
      setIsLoading(false);
    }
  }, [addData]);

  const columns = !enteredPricing?.[0]
    ? []
    : Object.keys(enteredPricing?.[0]).map((value) => {
        if (value === 'price') {
          return {
            accessorKey: 'price',
            header: 'Price Per Unit',
            Cell: ({ cell, row }) => fCurrency(cell.getValue()),
          };
        }
        return {
          accessorKey: value,
          header: capitalize(value),
        };
      });

  const [rowSelection, setRowSelection] = useState(false);

  const getFoodListForSelectedSupplier = (dataModel) => {
    try {
      const suppliersFoodList = safeJSONParse(dataModel?.supplier?.foodList);

      const foodSupplierIdList = suppliersFoodList.map((value) => value?.foodId)?.filter((value) => value);

      return allFoodData
        .filter((food) => foodSupplierIdList?.includes(food?.id))
        .map((value) => {
          return {
            ...value,
            unit: units?.filter((unitIndex) => unitIndex?.id === value?.unitId)?.[0],
            foodSupplierDetails: suppliersFoodList.filter((supplierFood) => supplierFood?.foodId === value?.id)?.[0],
          };
        });
    } catch (e) {
      return [];
    }
  };
  return (
    <Page title='Enter Checked Prices'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Enter Checked Prices'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Quotation',
              href: PATH_DASHBOARD.stocks.root,
            },
            { name: 'Enter Checked Prices' },
          ]}
          action={
            <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => manageModel('add')}>
              Add Checked Price
            </Button>
          }
        />

        {accessVerify('CALL_AND_GET_PRICES_VIEW') && (
          <DataGridTable
            name={'Enter Prices'}
            data={enteredPricing}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Prices</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Supplier Information
                  </Typography>
                  <Stack spacing={3}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        updateDataModel('supplier', newValue);
                      }}
                      options={supplierData?.map((value) => {
                        return {
                          ...value,
                          label: `${value?.name} (${value?.code})`,
                          value: value?.id,
                        };
                      })}
                      value={dataModel?.supplier?.name ? `${dataModel?.supplier?.name} (${dataModel?.supplier?.code})` : null}
                      renderInput={(params) => <TextField label='Select Selling Items' {...params} />}
                    />
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Prices For Each Food
                  </Typography>
                  <Stack spacing={3}>
                    {getFoodListForSelectedSupplier(dataModel)?.map((value) => (
                      <TextField
                        type={'number'}
                        onChange={(event) => {
                          updateDataModel(`foodPrice_Id_${value?.foodSupplierDetails?.id}`, event.target.value);
                        }}
                        label={`${value?.name} Price per 1 ${value?.unit?.symbol}`}
                      />
                    ))}
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton onClick={onSubmit} variant='contained' size='large' style={{ width: '100%' }} loading={isLoading}>
                    {isAdd ? 'Add Prices' : 'Save Changes'}
                  </LoadingButton>
                  <Button color='info' variant='outlined' size='large' style={{ width: '100%' }} onClick={() => manageModel('add')}>
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
