import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  stockBreakerData: [],
  stockHistory: [],
  addData: {},
  processStockBreaking: {},
  allStockStatus: [],
  sorted: [],
};

const slice = createSlice({
  name: 'Stock',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    // GET StockManagement
    getStocksSuccess(state, action) {
      state.data = action.payload.data;
    },

    // GET All Stock Status Success
    getAllStockStatusesSuccess(state, action) {
      state.getAllStockStatus = action.payload.data;
    },

    // GET StockManagement
    getStocksHistorySuccess(state, action) {
      state.stockHistory = action.payload.data;
    },

    addOrUpdateStocks(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    getStockBreakerSuccess(state, action) {
      state.stockBreakerData = action.payload.data;
    },

    updateStockBreakerSuccess(state, action) {
      state.processStockBreaking = action.payload.data;
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      state.checkout.activeStep = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getStocks(location, limit, code, codeArray) {
  return async () => {
    try {
      const response = await get(`Stock?location=${location}&limit=${limit}&code=${code}&codeArray=${codeArray}`);
      if (response.status) {
        dispatch(slice.actions.getStocksSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAllStockStatus(location, limit, code) {
  return async () => {
    try {
      const response = await get(`get-all-stock-status`);
      if (response.status) {
        dispatch(slice.actions.getAllStockStatusesSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStockHistory(code) {
  return async () => {
    try {
      const response = await get(`stock-history?code=${code}`);
      if (response.status) {
        dispatch(slice.actions.getStocksHistorySuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addStock(body) {
  return async () => {
    try {
      const response = await post(`Stock`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateStocks(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFromBreakerDetails(id) {
  return async () => {
    try {
      const response = await get(`StockBreaker?id=${id}`);
      if (response.status) {
        dispatch(slice.actions.getStockBreakerSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearStockBreakerData() {
  return async () => {
    try {
      dispatch(slice.actions.getStockBreakerSuccess({}));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateStockBreaking(body) {
  return async () => {
    try {
      const response = await put(`processStockBreaking`, body);
      if (response.status) {
        dispatch(slice.actions.updateStockBreakerSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearProcessStockBreaking() {
  return async () => {
    try {
      dispatch(slice.actions.updateStockBreakerSuccess({}));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateStock(body) {
  return async () => {
    try {
      const response = await put(`Stock`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateStocks(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
