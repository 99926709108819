import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';
import { MobileDateRangePicker } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { format, startOfDay, endOfDay } from 'date-fns';
import moment from 'moment/moment';
import { getHomeDashboardData } from '../../../../redux/slices/homeRedux/homeRedux';
import { safeJSONParse } from '../../../../utils/common';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({}));

export default function DataFilter({ data, setData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let usersListData;
  ({ data: usersListData } = useSelector((state) => state.user));
  let saleListData;
  ({ data: saleListData } = useSelector((state) => state.home));

  const [openPicker, setOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState([startOfDay(new Date()), endOfDay(new Date())]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  useEffect(() => {
    const startDate = moment(dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    dispatch(
      getHomeDashboardData({
        fromDate: startDate,
        toDate: endDate,
        locationId: selectedLocation?.id,
      })
    );
  }, [dateRange, selectedLocation]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    setData(
      saleListData
        ?.map((value) => {
          return {
            ...value,
            pos_items: safeJSONParse(value?.pos_items),
            pos_payments: safeJSONParse(value?.pos_payments),
          };
        })
        .filter((value) => {
          return selectedUsers?.length > 0 ? selectedUsers?.map((value) => value?.userId).includes(Number(value?.pos_entered_userId)) : value;
        })
    );
  }, [saleListData, selectedUsers]);

  const handleOpenPicker = () => {
    setOpenPicker(true);
  };

  const handleClosePicker = () => {
    setOpenPicker(false);
  };

  const handleChangeDateRange = (newValue) => {
    setDateRange(newValue);
  };
  const formatDateRangeDisplay = ([start, end]) => {
    if (!start || !end) return '';
    return `${format(start, 'dd/MM/yyyy')} - ${format(end, 'dd/MM/yyyy')}`;
  };

  const handleChange = (event) => {
    const selectedUserListTemp = event?.target?.value || [];
    setSelectedUsers((prevState) => {
      const addedArray = selectedUserListTemp.filter((element) => !prevState.map((user) => user.userId).includes(element));
      const removedArray = prevState.filter((user) => !selectedUserListTemp.includes(user.userId));
      const updatedArray = addedArray.map((userId) => ({
        userId: userId,
        dates: [],
        confirmedDates: [],
      }));
      return [...prevState, ...updatedArray].filter((user) => !removedArray.map((user) => user.userId).includes(user.userId));
    });
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <MobileDateRangePicker
          open={openPicker}
          onClose={() => setOpenPicker(false)}
          onOpen={() => setOpenPicker(true)}
          value={dateRange}
          onChange={(newValue) => {}}
          onAccept={(newValue) => {
            setDateRange(newValue);
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <TextField
              ref={inputRef}
              {...inputProps}
              InputProps={{
                ...InputProps,
              }}
              fullWidth
              label='Select Date Range'
              value={formatDateRangeDisplay(dateRange)}
              onClick={() => setOpenPicker(true)}
              readOnly
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id='user-selector-label'>User List (Sale Person)</InputLabel>
          <Select
            labelId='user-selector-label'
            id='user-selector'
            multiple
            value={selectedUsers.map((user) => user.userId)}
            onChange={handleChange}
            input={<OutlinedInput id='user-selector' label='Chip' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {selected.map((value) => {
                  const data = usersListData.find((user) => user.id === value);
                  return <Chip key={value} label={data?.name} />;
                })}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: '40%',
                },
              },
            }}
          >
            {usersListData.map((value) => (
              <MenuItem
                key={value?.id}
                value={value?.id}
                style={{
                  fontWeight: theme.typography.fontWeightMedium,
                }}
              >
                {value?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}
