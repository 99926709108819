import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Container, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { closeModal, getEvents, openModal, updateEvent } from '../../../../../redux/slices/templateRedux/calendar';
import useSettings from '../../../../../hooks/useSettings';
import useResponsive from '../../../../../hooks/useResponsive';
import Page from '../../../../../components/Page';
import Iconify from '../../../../../components/Iconify';
import { DialogAnimate } from '../../../../../components/animate';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import { CalendarStyle, CalendarToolbar } from '../../../../../sections/@dashboard/calendar';
import LeaveForm from './leaveForm';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getDepartments } from '../../../../../redux/slices/settingsManagementRedux/departmentsRedux';
import { getLeave, getLeaveById } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userLeaveRedux';
import { getUsers } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';

export default function LeaveCalender() {
  const { id } = useParams();
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const isDesktop = useResponsive('up', 'sm');

  const calendarRef = useRef(null);

  const [date, setDate] = useState(new Date());

  const [view, setView] = useState(isDesktop ? 'dayGridMonth' : 'listWeek');

  const [events, setEvents] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const { isOpenModal, selectedRange } = useSelector((state) => state.calendar);
  const { data, addData } = useSelector((state) => state.leave);

  let locationData;
  ({ data: locationData } = useSelector((state) => state.location));

  let departmentData;
  ({ data: departmentData } = useSelector((state) => state.department));

  const getLeaves = () => {
    if (id === 'all') {
      dispatch(getLeave());
    } else {
      dispatch(getLeaveById(id));
    }
  };

  useEffect(() => {
    dispatch(getEvents());
    dispatch(getUsers());
    getLeaves();
    dispatch(getDepartments());
  }, []);

  useEffect(() => {
    if (addData?.data) {
      getLeaves();
    }
  }, [addData]);

  useEffect(() => {
    setEvents(
      data.map((value) => {
        const start = moment(value.start, 'YYYY-MM-DD HH:mm:ss');
        const end = moment(value.end, 'YYYY-MM-DD HH:mm:ss');
        return {
          id: value.id,
          title: value.title,
          description: value.description,
          documents: value.documents,
          start: start.format('YYYY-MM-DDTHH:mm:ssZ'),
          end: end.format('YYYY-MM-DDTHH:mm:ssZ'),
          allDay: false,
          textColor: value.textColor,
          duration: end.diff(end.format('YYYY-MM-DD'), 'minutes'),
          leaveType: value?.leaveType,
          dayLeaveType: value?.dayLeaveType,
          userId: value.userId,
          status: value.status,
          approvedBy: value.approvedBy,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = isDesktop ? 'dayGridMonth' : 'listWeek';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isDesktop]);

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg) => {
    // Did this to make sure each day they have added the leave individually!
    setSelectedEvent({ id: null, start: arg.start, end: arg.start });
    dispatch(openModal());
  };

  const handleSelectEvent = (arg) => {
    setSelectedEvent(events?.filter((value) => value?.id == arg.event?.id)?.[0]);
    dispatch(openModal());
  };

  const handleResizeEvent = async ({ event }) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropEvent = async ({ event }) => {
    try {
      const eventData = events?.filter((value) => value?.id == event?.id)?.[0];
      setSelectedEvent({ ...eventData, start: event.start, end: event.end });
      dispatch(openModal());
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddEvent = () => {
    setSelectedEvent({});
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Page title='Calendar'>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          action={
            <>
              {id !== 'all' && (
                <Button variant='contained' startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />} onClick={handleAddEvent}>
                  Add Leave
                </Button>
              )}
            </>
          }
        />

        <Card>
          <CalendarStyle>
            <CalendarToolbar date={date} view={view} onNextDate={handleClickDateNext} onPrevDate={handleClickDatePrev} onToday={handleClickToday} onChangeView={handleChangeView} />
            <FullCalendar
              weekends
              editable
              droppable
              selectable
              events={events}
              ref={calendarRef}
              rerenderDelay={10}
              initialDate={date}
              initialView={view}
              // dayMaxEventRows={3}
              eventDisplay='block'
              headerToolbar={false}
              allDayMaintainDuration
              eventResizableFromStart
              select={handleSelectRange}
              eventDrop={handleDropEvent}
              eventClick={handleSelectEvent}
              eventResize={handleResizeEvent}
              height={isDesktop ? 720 : 'auto'}
              displayEventTime={true}
              plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
            />
          </CalendarStyle>
        </Card>

        <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
          <DialogTitle>{selectedEvent?.id ? 'Edit Leave Request' : 'Add Leave Request'}</DialogTitle>

          <LeaveForm event={selectedEvent || {}} range={selectedRange} onCancel={handleCloseModal} userId={id} locationData={locationData} departmentData={departmentData} />
        </DialogAnimate>
      </Container>
    </Page>
  );
}
