import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { fCurrency } from '../../../../../../utils/formatNumber';
import { description } from '../../../../../../_mock/text';

export default function PaymentTypeBlock({ title, description, price, onClickSound, addToCart, isIncrement }) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
      }}
    >
      <Card
        sx={{
          width: '100%',
          height: '100%',
          margin: '2px',
          border: '0.5px solid #E7E7E7',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        onClick={() => {
          onClickSound();
          addToCart();
        }}
      >
        <CardContent>
          <Typography gutterBottom variant='subtitle1' component='div'>
            {title}
          </Typography>
          <sub>{description}</sub>
        </CardContent>

        <LoadingButton
          variant='outlined'
          size='small'
          color={isIncrement ? (price > 0 ? 'success' : 'primary') : price < 0 ? 'success' : 'primary'}
          style={{
            width: '95%',
            margin: '2.5%',
          }}
        >
          <>Add More ({fCurrency(price)})</>
        </LoadingButton>
      </Card>
    </div>
  );
}
