import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { capitalize, safeJSONParse } from '../../../utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import useSettings from '../../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { dispatch, useSelector } from '../../../redux/store';
import DataGridTable from '../../../components/table/DataGridTable';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { getStocks } from '../../../redux/slices/stockManagementRedux/stockRedux';
import { Box, Card, Chip, Container, Grid, IconButton, Stack, TextField } from '@mui/material';
import { updateStockStatus } from '../../../redux/slices/stockManagementRedux/stockTransferRedux';

export const SetToStoreStatus = () => {
  const { themeStretch } = useSettings();
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState({ status: 1, barcode: [], stockIdList: [] });
  const [rowSelection, setRowSelection] = useState(false);
  const { data: stocks } = useSelector((state) => state?.stock);
  const { addData: stockAddedStatus } = useSelector((state) => state?.stockTransfer);

  useEffect(() => {
    if (data?.barcode?.length > 0) {
      dispatch(getStocks(-1, -1, -1, data?.barcode));
    }
  }, [data?.barcode]);

  const columns = tableData?.[0]
    ? Object.keys(tableData?.[0])
        .map((value) => {
          if (value === 'status') {
            return {
              accessorKey: 'status',
              header: 'Status',
              Cell: ({ cell, row }) => (row?.original?.status === 'Eligible' ? <Chip label={'Eligible'} color={'success'} /> : <Chip label={'Not Eligible'} color={'error'} />),
            };
          }
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        .filter(Boolean)
    : [];

  useEffect(() => {
    const reversedData = [...data.barcode].reverse();
    setData((prevData) => ({
      ...prevData,
      stockIdList: [],
    }));
    setTableData(
      reversedData.map((barcodeIndex) => {
        const stockInfo = stocks.find((stock) => stock?.stockCode === barcodeIndex);
        setData((prevData) => ({
          ...prevData,
          stockIdList: [
            ...prevData.stockIdList,
            {
              stockId: stockInfo?.stockId,
              currentQty: stockInfo?.currentQty,
              costPerUnit: stockInfo?.costPerUnit,
            },
          ],
        }));
        return {
          barCode: barcodeIndex,
          foodInfo: stockInfo ? safeJSONParse(stockInfo.foodInfo)?.foodName : 'No Data',
          transferingQty: stockInfo ? `${stockInfo.currentQty} ${safeJSONParse(stockInfo.unitInfo)?.unitName}` : '-',
          currentStatus: stockInfo ? `${stockInfo.stockStatusName}` : '-',
          status: ![2].includes(stockInfo?.stockStatus) || !stockInfo ? 'Not Eligible' : 'Eligible',
        };
      })
    );
  }, [data.barcode, stocks]);

  useEffect(() => {
    if (stockAddedStatus?.data?.affectedRows > 0) {
      setData({ status: 1, barcode: [], stockIdList: [] });
      setTableData([]);
    }
  }, [stockAddedStatus]);

  const setToCooking = () => {
    const invalidBarcodes = tableData?.filter((value) => value?.transferingQty === '-' || value?.foodName === 'No Data');
    const barcodesInWrongStatus = tableData?.filter((value) => value?.status === 'Not Eligible');
    if (invalidBarcodes.length > 0) {
      toast.error(`${invalidBarcodes?.length} Invalid Barcodes Identified! Please remove them and try again.`);
    } else if (barcodesInWrongStatus.length > 0) {
      toast.error(`${barcodesInWrongStatus?.length} Barcodes are in current delivery status.`);
    } else {
      dispatch(updateStockStatus(data));
    }
  };

  return (
    <Page title='Set To Cooking Status'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Set To Store Status'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Store', href: PATH_DASHBOARD.stocks.transfer.receiver }, { name: 'Set To Store Status' }]}
          action={[]}
        />
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <LoadingButton
                  variant='contained'
                  size='large'
                  style={{ width: '100%' }}
                  onClick={() => {
                    setToCooking();
                  }}
                >
                  Set to Store Status
                </LoadingButton>
                <Card
                  sx={{
                    p: 3,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eee',
                    },
                  }}
                >
                  <TextField
                    type='text'
                    label={`Enter Barcode`}
                    sx={{ width: '100%' }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.target.select();
                        if (!data?.barcode?.includes(event.target.value)) {
                          setData({
                            ...data,
                            barcode: [].concat(data.barcode, [event.target.value]),
                          });
                        }
                      }
                    }}
                  />
                  <br />
                  <br />

                  <DataGridTable
                    name={'Entered Items'}
                    data={tableData}
                    column={columns}
                    isLoading={false}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    isRowClickable={true}
                    enableRowSelection={false}
                    keyField={'stockId'}
                    enablePinning={false}
                    enableRowActions={true}
                    maxHeight={`40vh`}
                    rowsPerPage={8}
                    renderRowActionItems={(row) => [
                      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }} key={`view-stock-${row.original.stockId}`}>
                        <IconButton
                          color='primary'
                          onClick={() => {
                            setData({
                              ...data,
                              barcode: data.barcode.filter((barcode) => barcode !== row.original.barCode),
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>,
                    ]}
                  />
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};
