import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Box, Card, Grid, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFSwitch, RHFTextField } from '../../../../../components/hook-form';
import { dispatch, useSelector } from '../../../../../redux/store';
import { getSpecificUserData, updateUser } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { userModel } from '../../../../../models/userModel';

export default function UserSalary({ userId }) {
  const { selectedUser, addData } = useSelector((state) => state.user);
  const [selectedObject, setSelectedObject] = useState({});

  useEffect(() => {
    if (userId) dispatch(getSpecificUserData(userId));
  }, [userId]);

  useEffect(() => {
    if (addData?.data?.affectedRows > 0) {
      dispatch(getSpecificUserData(userId));
    }
  }, [addData]);

  useEffect(() => {
    setSelectedObject(selectedUser?.[0]);
    reset(selectedUser?.[0]);
  }, [selectedUser]);

  const methods = useForm({
    defaultValues: userModel(selectedObject),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    try {
      const tempDataObj = { ...selectedUser };
      Object.keys(value).forEach((key) => {
        tempDataObj[key] = value[key] || null;
      });
      dispatch(updateUser(tempDataObj));
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ py: 4, px: 3 }}>
            <Box
              sx={{
                display: 'grid',
                py: 3,
                rowGap: 2,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(3, 1fr)',
                },
              }}
            >
              <Controller
                name='act'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={field.value || ''}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    options={['Wages Board', 'Shop And Office']}
                    renderInput={(params) => <TextField label='Act' {...params} />}
                  />
                )}
              />

              <RHFTextField name='daysPerMonth' label='Working Days Per Month' />
              <RHFTextField name='rostAbsentDeduction' label='Rost Absent Deduction' />
              <RHFTextField name='timeAllowance' label='Time Allowance' />
              <RHFTextField name='lateCheckingWarningCount' label='Late Checking Warning Count' />
              <RHFTextField name='basicSalary' label='Basic Salary' />
              <RHFTextField name='inspectionAllowance' label='Inspection Allowance' />
              <RHFTextField name='workingHoursPerDay' label='Working Hours Per Day' />
              <RHFTextField name='oTpaymentTimes' label='OT Payment Times' />
              <RHFTextField name='weekDayCommission' label='Weekday Commission' />
              <RHFTextField name='weekMinimumDayEarningForCommission' label='Week Minimum Day Earning for Commission' />
              <RHFTextField name='weekendDayCommission' label='Weekend Day Commission' />
              <RHFTextField name='weekendMinimumDayEarningForCommission' label='Weekend Minimum Day Earning for Commission' />
              <RHFSwitch name='inspectionElegible' label='Inspection Allowance Eligible' />
              <RHFSwitch name='timeAllowanceElegible' label='Time Allowance Eligible' />
              <RHFSwitch name='salesBonusElegible' label='Sales Bonus Eligible' />
            </Box>

            <Stack spacing={3} alignItems='flex-end' sx={{ mt: 3 }}>
              <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
