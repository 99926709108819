import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import LocationManagement from '../pages/dashboard/settings/location';
import UserManagementAccount from '../pages/dashboard/hr/userManagement/userAccount';
import TimeLog from '../pages/dashboard/hr/timeLog';
import SalesList from '../pages/dashboard/sales/salesList';
import SalaryManagement from '../pages/dashboard/hr/salary';
import PaySheetIndex from '../pages/dashboard/hr/paysheet/paySheetIndex';
import SupplierManagement from '../pages/dashboard/suppliers/suppliers';
import FoodList from '../pages/dashboard/food/foodList';
import FoodCategoryManagement from '../pages/dashboard/food/foodCategory';
import { dispatch } from '../redux/store';
import { getLocations } from '../redux/slices/settingsManagementRedux/locationsRedux';
import { getUnits } from '../redux/slices/stockManagementRedux/unitRedux';
import EnterPrices from '../pages/dashboard/purchasing/enterPrices';
import AddToCart from '../pages/dashboard/purchasing/addToCart';
import PurchaseNotes from '../pages/dashboard/purchasing/purchaseNotes';
import Receiving from '../pages/dashboard/purchasing/receiving';
import FoodEditor from '../pages/dashboard/food/foodEditor';
import StockList from '../pages/dashboard/stock/inventoryManagement/stockList';
import SingleStockItem from '../pages/dashboard/stock/inventoryManagement/singleStockItem';
import Sender from '../pages/dashboard/stock/transferManagement/sender';
import Receiver from '../pages/dashboard/stock/transferManagement/receiver';
import ViewTransfer from '../pages/dashboard/stock/transferManagement/viewTransfer';
import POS from '../pages/dashboard/pos/POS';
import { SetToCookingStatus } from '../pages/dashboard/kitchen/setToCookingStatus';
import { SetToStoreStatus } from '../pages/dashboard/kitchen/setToStoreStatus';
import { CookingProcess } from '../pages/dashboard/kitchen/cookingProcess';
import KitchenDisplay from '../pages/dashboard/kitchen/kitchenDisplay';
import AssetsList from '../pages/dashboard/assets/assetsList';
import ViewStockOrders from '../pages/dashboard/stock/stockOrder/viewStockOrders';
import CreateStockOrders from '../pages/dashboard/stock/stockOrder/createStockOrders';
import AssetCategories from '../pages/dashboard/assets/assetCategories';
import Cashflow from '../pages/dashboard/finance/cashflow';
import PaymentTypes from '../pages/dashboard/finance/paymentTypes';
import CashflowTransfers from '../pages/dashboard/finance/cashflowTransfers';
import StockAvailabilityChecker from '../pages/dashboard/stock/stockAvailabilityChecker';
import CustomerList from '../pages/dashboard/sales/customerList';
import Rost from '../pages/dashboard/rost/rost';
import InspectionTypeList from '../pages/dashboard/hr/inspection/inspectionTypeList';
import InspectionTemplateList from '../pages/dashboard/hr/inspection/inspectionTemplateList';
import InspectionExamin from '../pages/dashboard/hr/inspection/inspectionExamin';
import LeaveCalender from '../pages/dashboard/hr/userManagement/components/leaveCalender';
import Breaker from '../pages/dashboard/stock/breaker';
import { getPermissions } from '../redux/slices/commonRedux/commonRedux';
import HomeDashboard from '../pages/dashboard/home/homeDashboard';
import CashFlowCategories from '../pages/dashboard/finance/cashFlowCategories';
import Aurudu from '../pages/aurudu/aurudu';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  useEffect(() => {
    dispatch(getPermissions());
    dispatch(getLocations());
    dispatch(getUnits());
  }, []);

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <HomeDashboard /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralEcommerce /> },
        { path: 'locations', element: <LocationManagement /> },
        { path: 'pos', element: <POS /> },
        {
          path: 'foods',
          children: [
            { path: 'types/:type', element: <FoodList /> },
            { path: 'category/:type', element: <FoodCategoryManagement /> },
            { path: 'view-all', element: <StockList /> },
            { path: 'food/:type/manage/:foodId', element: <FoodEditor /> },
          ],
        },
        {
          path: 'suppliers',
          children: [
            {
              path: 'stock',
              element: <SupplierManagement isStockSupplier={true} />,
            },
            {
              path: 'assetsAndServices',
              element: <SupplierManagement isStockSupplier={false} />,
            },
          ],
        },
        {
          path: 'purchasing',
          children: [
            { path: 'enterPrices', element: <EnterPrices /> },
            { path: 'purchaseNotes', element: <PurchaseNotes /> },
            { path: 'addToCart', element: <AddToCart /> },
            { path: 'receiving', element: <Receiving /> },
          ],
        },
        {
          path: 'kitchen',
          children: [
            { path: 'cooking', element: <SetToCookingStatus /> },
            { path: 'cooking', element: <SetToCookingStatus /> },
            { path: 'store', element: <SetToStoreStatus /> },
            { path: 'cookingProcess', element: <CookingProcess /> },
            { path: 'display', element: <KitchenDisplay /> },
          ],
        },
        {
          path: 'stock',
          children: [
            { path: 'view-all', element: <StockList /> },
            { path: 'breaker', element: <Breaker /> },
            {
              path: 'transfer',
              children: [
                { path: 'view', element: <ViewTransfer /> },
                { path: 'sender', element: <Sender /> },
                { path: 'receiver', element: <Receiver /> },
              ],
            },
            {
              path: 'stockOrder',
              children: [
                { path: 'view', element: <ViewStockOrders /> },
                { path: 'create', element: <CreateStockOrders /> },
              ],
            },
            {
              path: 'availability',
              children: [{ path: 'checker', element: <StockAvailabilityChecker /> }],
            },
            { path: 'single-stock-item/:id', element: <SingleStockItem /> },
          ],
        },
        {
          path: 'sales',
          children: [
            { path: 'saleList', element: <SalesList /> },
            { path: 'customerList', element: <CustomerList /> },
          ],
        },
        {
          path: 'hr',
          children: [
            {
              path: 'employees',
              children: [
                { path: 'list/:type', element: <UsersList /> },
                { path: 'account/:id', element: <UserManagementAccount /> },
              ],
            },
            {
              path: 'salary',
              children: [
                { path: 'timeLog', element: <TimeLog /> },
                { path: 'generateSalary', element: <SalaryManagement /> },
              ],
            },
            {
              path: 'inspection',
              children: [
                { path: 'typeList', element: <InspectionTypeList /> },
                {
                  path: 'inspectionTemplateList',
                  element: <InspectionTemplateList />,
                },
                {
                  path: 'inspectionExamin',
                  element: <InspectionExamin />,
                },
              ],
            },
            {
              path: 'rost',
              children: [{ path: 'manager', element: <Rost /> }],
            },
            {
              path: 'leave',
              children: [{ path: 'manager/:id', element: <LeaveCalender /> }],
            },
          ],
        },
        {
          path: 'assets',
          children: [
            { path: 'list', element: <AssetsList /> },
            { path: 'categories', element: <AssetCategories /> },
          ],
        },
        {
          path: 'finance',
          children: [
            {
              path: 'cashflow',
              element: <Cashflow />,
            },
            {
              path: 'cashflowTransfers',
              element: <CashflowTransfers />,
            },
            {
              path: 'cashflowCategories',
              element: <CashFlowCategories />,
            },
            {
              path: 'paymentTypes',
              element: <PaymentTypes />,
            },
          ],
        },
        {
          path: 'settings',
          children: [
            {
              path: 'general',
              children: [{ path: 'locations', element: <LocationManagement /> }],
            },
            {
              path: 'account',
              children: [{ path: 'userAccount/:id', element: <UserManagementAccount /> }],
            },
          ],
        },
        {
          path: 'e-commerce',
          children: [
            {
              element: <Navigate to='/dashboard/e-commerce/shop' replace />,
              index: true,
            },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to='/dashboard/user/profile' replace />,
              index: true,
            },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            {
              element: <Navigate to='/dashboard/invoice/list' replace />,
              index: true,
            },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            {
              element: <Navigate to='/dashboard/blog/posts' replace />,
              index: true,
            },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            {
              element: <Navigate to='/dashboard/mail/all' replace />,
              index: true,
            },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/template/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/template/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/template/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/template/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/template/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/template/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/template/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/template/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/template/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/template/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/template/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/template/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/template/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/template/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/template/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/template/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/template/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/template/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/template/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/template/UserList')));
const UsersList = Loadable(lazy(() => import('../pages/dashboard/hr/userList')));
const UserAccount = Loadable(lazy(() => import('../pages/template/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/template/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/template/Chat')));
const Mail = Loadable(lazy(() => import('../pages/template/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/template/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/template/Kanban')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/template/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/errorPages/maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/template/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/template/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/errorPages/page500')));
const NotFound = Loadable(lazy(() => import('../pages/errorPages/page404')));
