import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardActions, CardContent, IconButton, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment/moment';
import { truncateStringValue } from '../../../../../../utils/common';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CakeIcon from '@mui/icons-material/Cake';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@material-ui/icons/Edit';
import { fCurrency } from '../../../../../../utils/formatNumber';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

export default function CustomerDetailsBlock({ value, data, setData, onClickSound, onChangeTab }) {
  const theme = useTheme();

  return (
    <>
      <Card
        sx={{
          width: 300,
          border: 'black',
          boxShadow:
            value?.id === data?.id ? `${theme.palette.primary.main} 0px 0.0625em 0.0625em, ${theme.palette.primary.light} 0px 0.125em 0.5em, ${theme.palette.primary.main} 0px 0px 0px 1px inset` : '',
          transition: 'transform 0.6s',
          transform: value?.id === data?.id ? 'scale(1.1)' : 'scale(1)',
          margin: '10px',
          cursor: 'pointer',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
      >
        <CardContent>
          <Typography gutterBottom variant='h4' component='div'>
            {truncateStringValue(value?.name, 20)}
          </Typography>
          <Typography variant='body2' color='text.secondary' style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneAndroidIcon style={{ marginRight: '4px' }} /> {value?.phone}
          </Typography>
          <Typography variant='body2' color='text.secondary' style={{ display: 'flex', alignItems: 'center' }}>
            <CakeIcon style={{ marginRight: '4px' }} /> {moment(value?.birthday).format('DD MMMM')}
          </Typography>
          <Typography variant='body2' color='text.secondary' style={{ display: 'flex', alignItems: 'center' }}>
            <LoyaltyIcon style={{ marginRight: '4px' }} /> {fCurrency(value?.totalAmount)}
          </Typography>
        </CardContent>
        <CardActions>
          <LoadingButton
            variant={value?.id === data?.id && !data?.isEdit ? 'contained' : 'outlined'}
            size='medium'
            style={{
              width: '95%',
            }}
            onClick={() => {
              onClickSound();
              setData((tempData) => {
                return {
                  ...tempData,
                  customerInfo: {
                    id: value?.id,
                    name: value?.name,
                    phone: value?.phone,
                    birthdayMonth: Number(moment(value?.birthday).format('MM')),
                    birthdayDay: Number(moment(value?.birthday).format('DD')),
                    isEdit: false,
                  },
                };
              });
              onChangeTab('payments');
            }}
          >
            {value?.id === data?.id && !data?.isEdit ? 'Selected' : `Select ${truncateStringValue(value?.name, 15)}`}
          </LoadingButton>
          <LoadingButton
            color='warning'
            variant={value?.id === data?.id && data?.isEdit ? 'contained' : 'outlined'}
            size='medium'
            style={{
              width: '5%',
            }}
            onClick={() => {
              onClickSound();
              setData((tempData) => {
                return {
                  ...tempData,
                  customerInfo: {
                    id: value?.id,
                    name: value?.name,
                    phone: value?.phone,
                    birthdayMonth: Number(moment(value?.birthday).format('MM')),
                    birthdayDay: Number(moment(value?.birthday).format('DD')),
                    isEdit: true,
                  },
                };
              });
            }}
          >
            <EditIcon />
          </LoadingButton>
        </CardActions>
      </Card>
    </>
  );
}
