import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, ...other }) {
  const { control } = useFormContext();

  const capitalizeText = (text) => {
    return text.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          value={value || ''}
          onChange={(e) => onChange(capitalizeText(e.target.value))} // Use the capitalizeText function on change
          fullWidth
          error={!!error}
          helperText={error?.message}
          InputLabelProps={{ shrink: Boolean(value) }}
          notched={{ shrink: Boolean(value) }}
          {...other}
        />
      )}
    />
  );
}
