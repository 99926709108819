import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { deleteMethod, get, post, put } from '../../../inteceptor';
import moment from 'moment';
import { updateCurrentLoggedInUser } from '../hrRedux/UserManagementRedux/userManagementRedux';

const initialState = {
  error: null,
  time: null,
  data: [],
  addData: {},
  addDataPOSItem: {},
  addCustomerData: {},
  drawerOpeningStatus: {},
  posDrawerData: {},
  customer: [],
  sorted: [],
  saleTypes: [],
  viewPosStatus: false,
  viewPosCloseStatus: false,
};

const slice = createSlice({
  name: 'POS',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    // Update Pos status
    updatePosStatus(state, value) {
      state.viewPosStatus = value.payload;
    },
    // Update Pos status
    updatePosCloseStatus(state, value) {
      state.viewPosCloseStatus = value.payload;
    },

    // GET SaleManagement
    getCustomerSuccess(state, action) {
      state.customer = action.payload.data;
    },

    // GET SaleManagement
    getAllSaleTypesSuccess(state, action) {
      state.saleTypes = action.payload.data;
    },
    // GET SaleManagement
    getDrawerOpeningStatus(state, action) {
      state.drawerOpeningStatus = action.payload.data;
    },
    // GET POS Drawer Data
    getPosDrawerData(state, action) {
      state.posDrawerData = action.payload.data;
    },
    // GET Pos Data
    getPosSuccess(state, action) {
      state.data = action.payload.data;
    },

    addOrUpdatePos(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    addOrUpdatePOSItemStatus(state, action) {
      state.addDataPOSItem = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAddOrUpdatePos(state) {
      state.addData = { data: null, time: moment.now().toString() };
    },

    addOrUpdateCustomer(state, action) {
      state.addCustomerData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------
export function viewPOSTab() {
  return async () => {
    dispatch(slice.actions.updatePosStatus(true));
  };
}
export function hidePOSTab() {
  return async () => {
    dispatch(slice.actions.updatePosStatus(false));
  };
}
// ----------------------------------------------------------------------
export function viewPOSCloseTab() {
  return async () => {
    dispatch(slice.actions.updatePosCloseStatus(true));
  };
}
export function hidePOSCloseTab() {
  return async () => {
    dispatch(slice.actions.updatePosCloseStatus(false));
  };
}

export function clearAddData() {
  return async () => {
    dispatch(slice.actions.clearAddOrUpdatePos());
  };
}

export function openPOSDrawer(body) {
  return async () => {
    try {
      const response = await post(`openPOSDrawer`, body);
      if (response.status) {
        dispatch(slice.actions.getDrawerOpeningStatus(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function closePOSDrawer(body) {
  return async () => {
    try {
      const response = await put(`closePOSDrawer`, body);
      if (response.status) {
        dispatch(slice.actions.getDrawerOpeningStatus(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPOSDrawerDetails(id) {
  return async () => {
    try {
      const response = await get(`getDrawerPayments?id=${id}`);
      if (response.status) {
        dispatch(slice.actions.getPosDrawerData(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserDataAfterUpdate() {
  return async () => {
    try {
      post('auth', { token: localStorage.getItem('authTkn') }).then((res) => {
        if (res.status) {
          localStorage.setItem('authenticatedUser', JSON.stringify(res?.userData));
          localStorage.setItem('permissionsList', JSON.stringify(res?.userData?.permissions));
          updateCurrentLoggedInUser(res?.userData);
        }
      });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllSaleTypes() {
  return async () => {
    try {
      const response = await get(`allSaleTypes`);
      if (response.status) {
        dispatch(slice.actions.getAllSaleTypesSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPos(locationId) {
  return async () => {
    try {
      const response = await get(`pos?locationId=${locationId}`);
      if (response.status) {
        dispatch(slice.actions.getPosSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getForKitchenDisplay(locationId) {
  return async () => {
    try {
      const response = await get(`getForKitchenDisplay?locationId=${locationId}`);
      if (response.status) {
        dispatch(slice.actions.getPosSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addPosSale(body) {
  return async () => {
    try {
      const response = await post(`pos`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdatePos(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markPOSAsSold(body) {
  return async () => {
    try {
      const response = await put(`markPOSAsSold`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdatePos(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePOSItemStatus(body) {
  return async () => {
    try {
      const response = await put(`updatePOSItemStatus`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdatePOSItemStatus(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updatePosStatus(body) {
  return async () => {
    try {
      const response = await put(`updatePosStatus`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdatePos(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSale(body) {
  return async () => {
    try {
      const response = await put(`Sale`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdatePos(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSale(body) {
  return async () => {
    try {
      const response = await deleteMethod(`sale`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdatePos(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
